
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    loading_text: "loading recents...",
  },
  it: {
    loading_text: "sto caricando le chiamate recenti...",
  }
});
  
  export default localeStrings;