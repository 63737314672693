import React from 'react';
import { ListItem, List, Popover } from 'framework7-react';
import { connect } from 'react-redux';
import {RaApiRooms} from 'pikkart-remote-assistance';
import localeStrings from './rooms-popover-local';

class RoomsPopover extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      errormessage: null,
      roomMute: false,
    };
  }


  render() {
    return (
      <Popover className={"popover-menu-room"} key={'popover-menu-key-room'}>
        <List>
          <ListItem key='room-mute' popoverClose title={this.state.roomMute ? localeStrings.unmuteRoomText : localeStrings.muteRoomText} onClick={(e) => { this.changeRoomMute(this.props.room); }}></ListItem>
          <ListItem key='room-del' popoverClose title={localeStrings.deleteRoomText} onClick={(e) => { this.props.deleteRoomClick();}}></ListItem>
        </List>
      </Popover>)
  }

  changeRoomMute = (room) => {

    let self = this;
    const errorFn = (errorMsg) => {
      if (errorMsg !== this.state.errormessage) {
        this.setState({
          errormessage: errorMsg
        });
      }
    }

    let newMuteState = !room.Mute;
    RaApiRooms.setMute(room.UniqueId, newMuteState)
      .then(function (response) {

        if (response.result.success === true) {
          room.Mute = newMuteState;
          self.setState({errormessage: null, roomMute: newMuteState });
        } else {
          errorFn(response.result.message);
        }
      })
      .catch(function (errorMessage) {
        errorFn(errorMessage);
      });
  }

  componentDidMount = () => {
    
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (this.props.room && 
      (!prevProps.room || (prevProps.room && prevProps.room.Mute !== this.props.room.Mute))) {
      this.setState({roomMute: this.props.room.Mute });
    }
  }
};

// #region Redux
const mapStateToProps = state => {
  return {
    
  };
};

// #endregion

export default connect(mapStateToProps)(RoomsPopover);