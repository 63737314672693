
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    title: "Select a contact",
    no_contacts: "Nothing found",
    search_placeholder: "Search...",
  },
  it: {
    title: "Seleziona il contatto",
    no_contacts: "Nessun contatto trovato",
    search_placeholder: "Cerca...",
  }
});
  
  export default localeStrings;