
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    ipCam_notConnectedLabel: "Not connected",
    ipCam_enableWifiAlert_title: "Live Glasses",
    ipCam_enableWifiAlert_text: "Please enable your Router WIFI",
    ipCam_androidNotSupportedAlert_title: "Not available",
    ipCam_androidNotSupportedAlert_text: "Live Glasses are not available with this android version",
  },
  it: {
    ipCam_notConnectedLabel: "Non connessa",
    ipCam_enableWifiAlert_title: "Live Glasses",
    ipCam_enableWifiAlert_text: "Please enable your Router WIFI",
    ipCam_androidNotSupportedAlert_title: "Non disponibili",
    ipCam_androidNotSupportedAlert_text: "I Live Glasses non sono disponibili con questa versione di android",
  }
});
  
  export default localeStrings;