
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    ipCam_notConnected_message: "Live Glasses not connected",
    ipCam_renderingError_message: "Unable to render video stream",
  },
  it: {
    ipCam_notConnected_message: "Live Glasses non connessi",
    ipCam_renderingError_message: "Non riesco a renderizzare lo streaming video",
  }
});
  
  export default localeStrings;