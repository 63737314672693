
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    loading: "loading documents...",
    no_docs: "You have no documents to display",
    list_error: 'Oops...something went wrong',
    delete_confirm_message: 'Are you sure you want to delete this document?',
    delete_confirm_button_yes: 'Yes',
    search_placeholder: 'Search',
  },
  it: {
    loading: "sto caricando i documenti...",
    no_docs: "Non hai alcun documento al momento",
    list_error: 'Oops...qualcosa è andato storto',
    delete_confirm_message: 'Sei sicuro di voler eliminare questo documento?',
    delete_confirm_button_yes: 'Sì',
    search_placeholder: 'Cerca',
  }
});
  
  export default localeStrings;