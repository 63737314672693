import React from 'react';
import { List, Popup, ListItem, Link, Icon, Block, Chip, Col, Preloader } from 'framework7-react';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';
import ContactsList from '../../components/commons/contacts-list';
import {RaApiAuth, RaApiCallsTargets} from 'pikkart-remote-assistance';

class Commands extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      errormessage: null,
      showcontactslist: false,
      spinner: false,
      micMuted: false,
      audioMuted: false,
      invitedContact: null,
      updateIntervalId: null,
    };
  }

  render() {

    //if (this.props.username === null || this.props.confirmcode === null) {
    if (!RaApiAuth.isAuthenticated()) {
      return null;
    }

    let minh = window.innerHeight;

    if (window.innerWidth < window.innerHeight) {
      minh = window.innerWidth;
    }


    if (this.state.errormessage !== null) {
      return (

        <span>
          <Block style={{ marginTop: (minh * 0.1).toString() + "px" }}></Block>
          <Block className="row demo-preloaders align-items-stretch text-align-center">
            <Col>
              <h3>Error loading contacts list: {this.state.errormessage}</h3>
            </Col>
          </Block>
          <br></br><br></br>
          <div className='call-commands-container'>
            <Link className="fabIconLink fab-active" color='red' onClick={this.closeCall}>
              <Icon ios="f7:phone_down_fill" aurora="f7:phone_down_fill" md="material:call_end"></Icon>
            </Link>
          </div>
        </span>
      );
    }


    if (this.state.spinner === true && this.props.comunicationProviderRoom == null) {
      return (
        <Block className="row demo-preloaders align-items-stretch text-align-center participants">
          <Col>
            <Preloader size={42}></Preloader>
          </Col>
        </Block>
      );
    }

    if (this.props.activeCallTargets) {

      const contacts = this.props.activeCallTargets.map((item, index) => {
        return (
          <ListItem
            key={"licontact" + index}
            title={item.Contact.Name}
            footer={(item.Contact.Company != null ? item.Contact.Company.Name + (item.Contact.CompanyRole?' - ':'') : '') + item.Contact.CompanyRole}>
            <Chip outline text={RaApiCallsTargets.getTargetStatus(item.Status)} slot="after" />
          </ListItem>
        )
      });

      return (
        <span>
          <Block style={{ marginTop: (minh * 0.1).toString() + "px" }}>
            <List>
              {contacts}
            </List>
          </Block>
          <br></br><br></br>

          <Popup id="call-contacts-popup" className="contacts-popup ra-popup" colorTheme="custom" opened={this.state.showcontactslist === true} onPopupClose={(e) => { this.closeContactList(); }}>
            <ContactsList
              backPageClicked={(contact) => { this.inviteUserHandler(contact) }}
              closeContacts={(e) => { this.closeContactList() }} />
          </Popup>
        </span>
      );
    } else {
      return (
        <span>
          <Block style={{ marginTop: (minh * 0.1).toString() + "px" }}></Block>
          <Block className="row demo-preloaders align-items-stretch text-align-center">
            <Col>
              <h3>Loading participants...</h3>
            </Col>
          </Block>
        </span>);
    }
  }


  componentDidMount = () => {
    if (this.state.errormessage === null) {
     /* this.setState({ spinner: true });
      this.fetchContactsList();*/
    }
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    /*  if (this.props.comunicationProviderRoom && this.state.spinner === true) {
        this.setState({ spinner: false });
      }*/

  }

};

// #region Redux
const mapStateToProps = state => {
  return {
    profile: state.remoteAssistance.profile,
    mustUpdateTargets: state.app.mustUpdateTargets,
    activeCallTargets: state.remoteAssistance.activeCallTargets
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setMustUpdateTargets: (mustUpdateTargets) => dispatch(actions.setMustUpdateTargets(mustUpdateTargets)),
  }
};
// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(Commands);
