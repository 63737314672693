import React from 'react';
import { connect } from 'react-redux';
import { Page, Block, Row, Col } from 'framework7-react';
import { RaApiAuth } from 'pikkart-remote-assistance';

class Splash extends React.Component {

  constructor(props) {
    super(props);

    this.state = {

    };
  }

  render() {
    return (
      <Page>
        <Block textAlign="center" style={{marginTop: '100px'}} >
          <Row style={{ width: '100%' }}>
            <Col style={{ textAlign: 'center' }}>
              <img src={'./img/landing-image.png'} alt="logo" style={{width: '80%', maxWidth: '400px'}} />
            </Col>
          </Row>
        </Block>
      </Page>
    )
  }

  componentDidMount = () => {
  //  const self = this;
    this.showPreloader(true);
    let self = this;

     //aggiorno tutti i dati dell'utente
     RaApiAuth.loadAllUserData().then().catch((errorMsg)=> {
       console.error(errorMsg);
     }).finally();
     
    setTimeout(() => {
      this.showPreloader(false);
      self.$f7router.navigate('/main/', {reloadAll: true});
    }, 1000);
  }

  showPreloader = (show) => {
    if (show) {
      this.$f7.preloader.show('black');
    }
    else {
      this.$f7.preloader.hide();
    }
  }
};


// #region Redux
const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => {
  return {
  }
};

// #endregion

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default connect(mapStateToProps, mapDispatchToProps)(Splash);