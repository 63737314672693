import appActionTypes from './actionTypes';


export const setNewCallContactListOpen = (newCallContactsListOpen) => {
  return {
    type: appActionTypes.SET_NEWCALL_CONTACT_LIST_OPEN,
    newCallContactsListOpen: newCallContactsListOpen,
  };
}

export const setNewRoomContactListOpen = (newRoomContactsListOpen) => {
  return {
    type: appActionTypes.SET_NEWROOM_CONTACT_LIST_OPEN,
    newRoomContactsListOpen: newRoomContactsListOpen,
  };
}

export const setOrientation = (orientation) => {
  return {
    type: appActionTypes.SET_ORIENTATION,
    orientation: orientation,
  };
}

export const setMustUpdateTargets = (mustUpdateTargets) => {
  return {
    type: appActionTypes.SET_MUST_UPDATE_TARGETS,
    mustUpdateTargets: mustUpdateTargets,
  }
}

export const setNotificationServiceError = (notificationServiceError) => {
  return {
    type: appActionTypes.SET_NOTIFICATION_SERVICE_ERROR,
    notificationServiceError: notificationServiceError,
  }
}

export const setMainPageIndex = (mainPageIndex) => {
  return {
    type: appActionTypes.SET_MAINPAGE_INDEX,
    mainPageIndex: mainPageIndex,
  }
}


export const setLoadingRecents = (loadingRecents) => {
  return {
    type: appActionTypes.SET_LOADING_RECENTS,
    loadingRecents: loadingRecents,
  }
}

export const setRecents = (recents) => {
  return {
    type: appActionTypes.SET_RECENTS,
    recents: recents,
  }
}

export const setAppInit = (appInit) => {
  return {
    type: appActionTypes.SET_APP_INIT,
    appInit: appInit,
  }
}


