
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    videoTitle: 'VIDEO INPUT DEVICES',
    audioTitle: 'AUDIO INPUT DEVICES',
    inputVideoLabel: 'Main camera',
    inputArVideoLabel: 'Augmented reality camera',
    inputAudioLabel: 'Microphone',
    outputAudioLabel: 'Speakers',
  },
  it: {
    videoTitle: 'DISPOSITIVI VIDEO',
    audioTitle: 'DISPOSITIVI AUDIO',
    inputVideoLabel: 'Webcam principale',
    inputArVideoLabel: 'Webcam per Realtà Aumentata',
    inputAudioLabel: 'Microfono',
    outputAudioLabel: 'Altoparlanti',
  }
});
  
  export default localeStrings;