import React, { Component } from 'react';
import { App, View } from 'framework7-react';
import routes from './routes';
//import initReactFastclick from 'react-fastclick';
import axios from 'axios';
import { connect } from 'react-redux';
//import * as actions from './redux/actions';
import MenuLeft from './components/menu/left';
import ErrorBoundary from './error-boundary';

import { RaApiCalls } from 'pikkart-remote-assistance';

import phoneCallingAudio from './assets/mp3/phone-calling.mp3';
import phoneBusyAudio from './assets/mp3/phone-busy.mp3';
import phoneRingAudio from './assets/mp3/phone-ring.mp3';
import phoneUnavailableAudio from './assets/mp3/phone-unavailable.mp3';

class AppBoot extends Component {

  render() {

    // Valgono per tutti i verbi: GET, POST, PUT, DELETE
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['Cache-Control'] = 'no-cache';

  //  initReactFastclick();

    let theme = 'auto';
    if (document.location.search.indexOf('theme=') >= 0) {
      theme = document.location.search.split('theme=')[1].split('&')[0];
    }
    const f7Params = {
      id: 'com.pikkart.remoteAssistance',
      theme,
      routes,
      popup: {
        closeOnEscape: true,
      },
      sheet: {
        closeOnEscape: true,
      },
      popover: {
        closeOnEscape: true,
      },
      actions: {
        closeOnEscape: true,
      },
      navbar: {
        hideOnPageScroll: false,
        iosCenterTitle: false,
        mdCenterTitle: true
      },
      touch: {
        tapHold: true,
        fastClicks: true
      }
    }


    RaApiCalls.setPhoneCallingAudio(phoneCallingAudio);
    RaApiCalls.setPhoneBusyAudio(phoneBusyAudio);
    RaApiCalls.setPhoneRingAudio(phoneRingAudio);
    RaApiCalls.setPhoneUnavailableAudio(phoneUnavailableAudio);

    return (  //url="/welcome/" className="safe-areas" masterDetailBreakpoint={800}

     /*posizionata la MenuToolbar sotto al MenuLeft  viene mostrata sempre solo in alto, 
        se la sivuole in basso dovrebbe figlia diretta di una Page, dichiarata sotto una Navbar, 
        ma in questo caso verrebbe ricaricata ad ogni pagina*/

      <App params={f7Params}>
        <ErrorBoundary>
          <MenuLeft></MenuLeft>
          <View url="/" main /*removeElements={true}*/></View>
        </ErrorBoundary>
      </App>
    );

  }
}

// #region Redux
const mapStateToProps = state => {
  return {
   /* 
    incomingCall: state.remoteAssistance.incomingCall,*/
    isCordovaReady: state.remoteAssistance.isCordovaReady,
  };
};

const mapDispatchToProps = dispatch => {
  return {
  //  setMustUpdateTargets: (mustUpdateTargets) => dispatch(actions.setMustUpdateTargets(mustUpdateTargets)),
  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(AppBoot);