import React from 'react';
//import Commons from '../../Commons';

// import moment from 'moment-timezone';

class AppPageComponent extends React.Component {
/*
  constructor(props) {
    super(props);

    document.addEventListener("resume", () => {
      Commons.checkOpenerMessage(this.$f7router);
    }, false);
  }*/
};

// #
export default AppPageComponent;
