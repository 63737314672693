
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    inputArVideoReady: 'Webcam ready',
    inputArVideoNotReady: 'Webcam not ready',
    inputArVideoTraining: 'Preparing...',
  },
  it: {
    inputArVideoReady: 'Webcam pronta',
    inputArVideoNotReady: 'Webcam non pronta',
    inputArVideoTraining: 'Sto preparando la webcam...',
  }
});
  
  export default localeStrings;