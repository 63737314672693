
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    loading_ar_text: "AR Camera loading...",
  },
  it: {
    loading_ar_text: "Preparo la webcam per la realtà aumentata",
  }
});
  
  export default localeStrings;