import React from 'react';
import { PageContent, Page, Block } from 'framework7-react';
import { connect } from 'react-redux';
import AppPageComponent from '../../components/commons/app-page-component';
import MenuLeftButton from '../../components/menu/left-menu-button';
import MenuToolbar from '../../components/menu/toolbar';
import RedirectEvents from '../../components/commons/redirect-events';

class Credits extends AppPageComponent {


    constructor(props) {
        super(props);

        this.state = {

        };
    }


    render() {

        let pageContent = null;

        pageContent = (
            <Block className='setting-cards-containers' style={{margin: '0px'}}>
                <div style={{ textAlign: 'center' }}>
                    <img src={'./img/loghi_credits.png'} alt="logo" style={{ width: '30vh' }} />
                </div>
                <div style={{ textAlign: 'center' }}>
                    <img src={'./img/pikkart_credits.png'} alt="logo" style={{ width: '40vh' }} />
                </div>
            </Block>);

        return (
            <Page pageContent={false} colorTheme="custom">
                <MenuLeftButton></MenuLeftButton>

                <PageContent>

                    {pageContent}

                </PageContent>

                <RedirectEvents router={this.$f7router}></RedirectEvents>
                <MenuToolbar router={this.$f7router}></MenuToolbar>
            </Page>
        );
    }
};

// #region Redux
const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
};
// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(Credits);