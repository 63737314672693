
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    inputAudioNoOptions: 'No input audio found',
  },
  it: {
    inputAudioNoOptions: 'Nessun microfono rilevato',
  }
});
  
  export default localeStrings;