
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    no_calls: "No calls to display",
    list_error: 'Oops...something went wrong',
  },
  it: {
    no_calls: "Non hai alcuna chiamata",
    list_error: 'Oops...qualcosa è andato storto',
  }
});
  
  export default localeStrings;