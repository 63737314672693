
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    select_snapshot: 'Please select a snapshot from the list',
  },
  it: {
    select_snapshot: 'Seleziona un\'immagine dalla lista',
  }
});
  
  export default localeStrings;