
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    permissions_alert_message: "You need to grant permissions in order to start the call",
    permissions_alert_title: "Permissions",
    no_rooms: "No chat to display",
    rooms_block_title: "Messages",
    list_error: 'Oops...something went wrong',
    messages_title: 'Messages',
    search_placeholder: 'Search',
    delete_room_alert_title: 'Delete',
    delete_room_alert_text: 'Do you want to delete this chat?',
    nominutes_alert_title: "No minutes left",
    nominutes_alert_text: 'Sorry, you have no more minutes left, you need to buy more assistance minutes.',
    permission_alert_text: 'In order to receive calls when the app is closed you need to grant overlay permission from the settings',
    permission_alert_title: 'Overlay permission',
    permission_alert_text2: 'Without the overlay permission you will not receive call notification',
    other_settings_alert_text: 'In order to receive calls when the app is closed you need to grant the "Display pop-up windows while running in the background" and "Show on Lock screen" permissions',
    other_settings_alert_title: 'Notification permissions',
  },
  it: {
    permissions_alert_message: "Devi fornire i permessi per poter avviare la chiamata",
    permissions_alert_title: "Permessi",
    no_rooms: "Non hai alcuna chat attiva",
    rooms_block_title: "Messaggi",
    list_error: 'Oops...qualcosa è andato storto',
    messages_title: 'Messaggi',
    search_placeholder: 'Cerca',
    delete_room_alert_title: 'Elimina',
    delete_room_alert_text: 'Vuoi eliminare questa chat?',
    nominutes_alert_title: "Minuti terminati",
    nominutes_alert_text: 'Purtroppo hai terminati i minuti di assistenza a disposizione.',
    permission_alert_text: 'Per ricevere chiamate quando l\'app è chiusa è necessario garantire i permessi i overlay',
    permission_alert_title: 'Permessi di overlay',
    permission_alert_text2: 'Senza i permessi di overlay non verrai avvisato in caso di chiamata',
    other_settings_alert_text: 'Per ricevere chiamate quando l\'app è chiusa è necessario garantire i permessi "Visualizza finestre pop-up durante l\'esecuzione in background" e "Visualizzazione sulla schermata di blocco"',
    other_settings_alert_title: 'Permessi delle notifiche',
  }
});
  
  export default localeStrings;