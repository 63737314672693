import React from 'react';
import { connect } from 'react-redux';
import { Link, Navbar, NavLeft} from 'framework7-react';
import Commons from '../../Commons';

class MenuLeftButton extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    const portrait = Commons.isPortrait(this);

    let navLeft = null;
    if (portrait) {
      navLeft = (<Navbar large largeTransparent sliding={false}>
        <NavLeft>
          <Link panelOpen="left" iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu"></Link>
        </NavLeft></Navbar>);
    }
    return navLeft;
  }

  componentDidMount = () => {
    
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
  }
};

// #region Redux
const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => {
  return {

  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(MenuLeftButton);