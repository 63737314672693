
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    inputArVideoToggleLabel: 'Enable augmented reality',
  },
  it: {
    inputArVideoToggleLabel: 'Utilizza una webcam per la realtà aumentata',
  }
});
  
  export default localeStrings;