import * as firebase from "firebase/app";
import "firebase/messaging";

const initializedFirebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyAl6SKb89TWjLOveLCQ0piQrTHytgY6sGk",
  authDomain: "pikkart-telehealth.firebaseapp.com",
  databaseURL: "https://pikkart-telehealth.firebaseio.com",
  projectId: "pikkart-telehealth",
  storageBucket: "pikkart-telehealth.appspot.com",
  messagingSenderId: "767976034327",
  appId: "1:767976034327:web:677dea899f27a15ffecc52",
  measurementId: "G-ZGECLY7LJ1"
  });

export { initializedFirebaseApp };