import React from 'react';
import { Block, Row, Card, CardContent, PageContent, Button, Chip, Col, Gauge, List, ListInput, Popup, Page, Navbar, NavRight, Link } from 'framework7-react';
import { connect } from 'react-redux';
import Commons from '../../Commons'
import AppPageComponent from '../../components/commons/app-page-component';
import localeStrings from './profile-local';
import MenuLeftButton from '../../components/menu/left-menu-button';
import MenuToolbar from '../../components/menu/toolbar';
import LogoutLoader from '../../components/logout/logout-loader';
import RedirectEvents from '../../components/commons/redirect-events';
import { RaUI, RaApiAuth, RaApiContacts, RaStorage, RaApiPayments, RaApiProducts, RaLog, AVAILABILITY } from 'pikkart-remote-assistance';
import { isIOS } from 'react-device-detect';

class Profile extends AppPageComponent {

  constructor(props) {
    super(props);

    this.state = {
      userOnline: true,
      serviceUsagePerc: 0.2,
      firstSubscriptionDate: null,
      serviceMinutesLeft: 0,
      serviceUsage: 0,
      products: [],
      paymentMethod: null,
      selectedProduct: null
    };
  }

  render() {

    let logoutLoader = null;
    let pageContent = null;

    let profile = RaStorage.getProfile();
    let groups = null;
    let lang = null;
    let buttonsRow = null;


    if (!RaApiAuth.isAuthenticated()) {
      logoutLoader = (<LogoutLoader router={this.$f7router}></LogoutLoader>);
    }
    else {

      if (profile && profile.Groups && profile.Groups.length > 0) {
        groups = profile.Groups.map((item, index) => {
          return <Chip outline key={item.Name} text={item.Name} style={{ marginRight: '15px' }} />
        });
      }

      const languages = { EN: "English", IT: "Italiano", ES: "Espanol", FR: "Français" };
      if (profile && profile.SpokenLanguagesRegion && profile.SpokenLanguagesRegion.length > 0) {
        lang = profile.SpokenLanguagesRegion.map((item, index) => {
          return <Chip outline key={item} text={languages[item]} />
        });
      }


      const userOnline = this.state.userOnline;
      if (userOnline) {
        buttonsRow = (
          <Row tag="p">
            <Col tag="span">
              <Button raised fill onClick={(e) => this.switchUserStatus(AVAILABILITY.ONLINE)}>{localeStrings.available}</Button>
            </Col>
            <Col tag="span">
              <Button raised onClick={(e) => this.switchUserStatus(AVAILABILITY.OFFLINE)}>{localeStrings.unavailable}</Button>
            </Col>
          </Row>
        );
      } else {
        buttonsRow = (
          <Row tag="p">
            <Col tag="span">
              <Button raised onClick={(e) => this.switchUserStatus(AVAILABILITY.ONLINE)}>{localeStrings.available}</Button>
            </Col>
            <Col tag="span">
              <Button raised fill onClick={(e) => this.switchUserStatus(AVAILABILITY.OFFLINE)}>{localeStrings.unavailable}</Button>
            </Col>
          </Row>
        );
      }

      const productsList = this.state.products.map((item, index) => {
        return (<option key={index} value={item.Code}>{item.Name} (€ {item.Price})</option>);
      });

      pageContent = (<div>

        <Block textAlign="center">
          <Row>
            <Col width="30" style={{ textAlign: 'right' }}>
              <img alt={profile.Name ? profile.Name : "N.A."} src={RaUI.getAvatar(profile.Name)} width="48" style={{ borderRadius: "50%" }} />
            </Col>
            <Col width="70">
              <strong style={{ display: "block", fontSize: '120%' }}>{profile.Name ? profile.Name : "N.A."}</strong>
              <div style={{ display: profile.Company ? "block" : "none" }}>{profile.Company ? profile.Company.Name : null}</div>
              <div style={{ display: profile.CompanyRole ? "block" : "none" }}>{profile.CompanyRole}</div>
              <div style={{ display: profile.Email ? "block" : "none" }}>{profile.Email}</div>
            </Col>
          </Row>
        </Block>

        <div className="setting-cards-containers">

          <Card noShadow outline style={{ display: profile.PaidService === true ? "block" : "none" }}>
            <CardContent>
              <Block strong className="date">
                <Row>
                  <Col style={{ paddingTop: '5px' }}>{localeStrings.wallet_title}</Col>
                  <Col style={{ textAlign: 'right', display: this.state.firstSubscriptionDate != null ? 'block' : 'none' }}>
                    <Chip outline color="blue" text={this.state.serviceMinutesLeft + " " + localeStrings.wallet_mins_left} />
                  </Col>
                </Row>
                <Row style={{ paddingTop: '10px' }}>
                  <Col width="30" style={{ textAlign: 'right', display: this.state.firstSubscriptionDate != null ? 'block' : 'none' }}>
                    <Gauge
                      type="circle"
                      value={this.state.serviceUsagePerc}
                      size={100}
                      borderColor="#2196f3"
                      borderWidth={7}
                      valueText={`${this.state.serviceUsage} ` + localeStrings.wallet_mins}
                      valueFontSize={this.state.serviceUsage < 1000 ? 20 : 17}
                      valueTextColor="#2196f3"
                      labelText={localeStrings.wallet_of_usage}
                    />
                  </Col>
                  <Col width="70">
                    <List noHairlinesMd>
                      <ListInput
                        id="lstProducts"
                        label={localeStrings.wallet_buy_message}
                        type="select"
                        placeholder={localeStrings.wallet_choose_message}
                      >
                        {productsList}
                      </ListInput>
                    </List>
                    <Button id="add-pay-method" raised fill onClick={(e) => { this.addPaymentMethod(); }}>{localeStrings.wallet_add_payment_text}</Button>

                  </Col>
                </Row>
              </Block>
            </CardContent>
          </Card>

          <Card noShadow outline style={{ display: process.env.REACT_APP_VIDEOCALL_ENABLED === "true" ? "block" : "none" }}>
            <CardContent>
              <Block strong>
                <p className="date">{localeStrings.status}</p>
                <Block tag="p" style={{ textAlign: "center" }}>
                  {buttonsRow}
                </Block>
              </Block>
            </CardContent>
          </Card>

          <Card noShadow outline style={{ display: profile.Groups != null && profile.Groups.length > 0 ? "block" : "none" }}>
            <CardContent>
              <Block strong>
                <p className="date">{localeStrings.groups}</p>
                {groups}
              </Block>
            </CardContent>
          </Card>

          <Card noShadow outline style={{ display: profile.SpokenLanguagesRegion != null && profile.SpokenLanguagesRegion.length > 0 ? "none" : "none" }}>
            <CardContent>
              <Block strong>
                <p className="date">{localeStrings.languages}</p>
                {lang}
              </Block>
            </CardContent>
          </Card>

        </div>

        <div style={{ textAlign: 'right' }}>
          <Button onClick={(e) => {
            this.$f7.dialog.confirm(localeStrings.logout_alert_text, localeStrings.logout_alert_title, this.logout)
          }}>{localeStrings.logout_button}</Button>
        </div>

      </div>);

    }


    return (
      <Page pageContent={true} colorTheme="custom">

        <MenuLeftButton></MenuLeftButton>
        <PageContent>

          {logoutLoader}
          {pageContent}
          <Popup id="pay-popup" className="pay-popup ra-popup" ref={(el) => { this.payPopup = el }} opened={this.state.paymentMethod && this.state.selectedProduct} onPopupClosed={() => this.setState({ paymentMethod: null, selectedProduct: null })}>
            <Page>
              <Navbar title="Checkout">
                <NavRight>
                  <Link popupClose>{localeStrings.wallet_checkout_close}</Link>
                </NavRight>
              </Navbar>
              <Block>
                <p>{localeStrings.wallet_checkout_review_message}</p>
                <div className="data-table">
                  <table>
                    <tbody>
                      <tr>
                        <td className="label-cell"><span>{localeStrings.wallet_checkout_product}:</span></td>
                        <td className="numeric-cell"><span id="pay-product-name">{this.state.selectedProduct ? this.state.selectedProduct.Name : null}</span></td>
                      </tr>
                      <tr>
                        <td className="label-cell"><span>{localeStrings.wallet_checkout_price}:</span></td>
                        <td className="numeric-cell">&euro; <span id="pay-product-price">{this.state.selectedProduct ? this.state.selectedProduct.Price : null}</span></td>
                      </tr>
                      <tr>
                        <td className="label-cell">{localeStrings.wallet_checkout_card}:</td>
                        <td className="numeric-cell"><span id="pay-card-type">{this.state.paymentMethod ? this.state.paymentMethod.cardType : null}</span></td>
                      </tr>
                      <tr>
                        <td className="label-cell"></td>
                        <td className="numeric-cell"><span id="pay-card-number">{this.state.paymentMethod ? this.state.paymentMethod.description : null}</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <Button disabled={!this.state.paymentMethod} id="pay-button" raised fill onClick={(e) => { this.executePayment(); }}>{localeStrings.wallet_checkout_confirm}</Button>
                {localeStrings.formatString(localeStrings.wallet_checkout_agreement, this.props.appCompany ? this.props.appCompany.Name : '', <Link external href={this.props.appCompany ? this.props.appCompany.PrivacyUrl : ''}>{localeStrings.wallet_checkout_privacy}</Link>, <Link external href={this.props.appCompany ? this.props.appCompany.TermsOfUse : ''}>{localeStrings.wallet_checkout_conditions}</Link>)}

              </Block>
            </Page>
          </Popup>

        </PageContent>
        <MenuToolbar router={this.$f7router}></MenuToolbar>
        <RedirectEvents router={this.$f7router}></RedirectEvents>
      </Page>

    );
  }

  /**
   * cardType
   * description
   * nonce
   */
  addPaymentMethod() {

    let selectedProduct = this.getSelectedProduct();
    this.setState({ selectedProduct: selectedProduct });
    RaLog.log('selectedProduct', selectedProduct);

    let self = this;
    RaApiPayments.getPaymentMethod()
      .then(function (paymentMethod) {
        if (self.payPopup) {
          self.payPopup.open();
        }
        self.setState({ paymentMethod: paymentMethod, selectedProduct: selectedProduct });
      })
      .catch(function (errorMsg) {
        RaLog.log(errorMsg);
        self.setState({ paymentMethod: null, selectedProduct: null });
      });
  }

  getSelectedProduct = () => {
    let code = this.$$('#lstProducts option:checked').attr('value');
    if (this.state.products) {
      for (let i = 0; i < this.state.products.length; i++) {
        if (this.state.products[i].Code === code) {
          return this.state.products[i];
        }
      }
    }
    return null;
  }

  executePayment() {
    let self = this;

    if (self.paymentInProgress) {
      return;
    }

    const startPayment = () => {
      self.paymentInProgress = true;
      self.$$('#pay-button').attr('style', 'opacity: 0.3');
    }

    const stopPayment = () => {
      self.paymentInProgress = false;
      self.$$('#pay-button').attr('style', 'opacity: 1');
    }

    startPayment();
    RaApiPayments.sendPayment(self.$$('#lstProducts option:checked').attr('value'), this.state.paymentMethod.nonce)
      .then(function (response) {
        stopPayment();
        if (response.result.success) {
          Commons.showAlert(self, localeStrings.wallet_transaction_alert_success_text, localeStrings.wallet_transaction_alert_success_title);
        }
        else {
          let message = response.result.message;
          if (message && message.length > 50) {
            message = message.substring(0, 47) + '...';
          }

          console.log(message);
          Commons.showAlert(self, localeStrings.wallet_transaction_alert_error_text + ' ' + localeStrings.wallet_transaction_alert_detail_error_text, localeStrings.wallet_transaction_alert_error_title);
        }
        RaApiAuth.getProfile().then(function () {
          self.checkWalletInformations();
        });

        self.setState({ paymentMethod: null });
      })
      .catch(function (errorMessage) {
        stopPayment();
        RaLog.log(errorMessage);
      });

    /**
     * ottengo il token di braintree
     * 
     * chiamo il plugin cordova passando il token per aprire l'ui del pagamento
     * 
     * invio il pagamento con il metodo nonce utilizzato
     */
    /*if (RaCordova.isCordova()) {
        RaApiPayment.getBrainTreeToken()
            .then(function (response) {
                if (response.result.success === true) {
                    successFn(response.data);
                } else {
                    errorFn(response.result.message);
                }
            })
            .catch(function (response) {
                errorFn(response.message);
            });

        const errorFn = (errorMsg) => {
            this.$f7.dialog.alert(errorMsg, 'Unable to retrieve braintreetoken');
        }

        const successFn = (token) => {
            RaLog.log('BrainTreeToken ' + token);
            RaApiPayment.getPaymentMethodNonce(token)
                .then(function (paymentMethodNonce) {
                    RaLog.log('PaymentMethodNonce ' + paymentMethodNonce);
                    RaApiPayment.sendPayment('IGV-RA-Minutes-60', paymentMethodNonce)
                        .then(function (paymentMethodNonce) {
                        })
                        .catch(function (response) {
                            RaLog.log(response.message);
                        });;
                })
                .catch(function (response) {
                    RaLog.log(response.message);
                });
        }
    }*/
  }

  logout = () => {
    setTimeout(() => {
      RaApiAuth.doLogout();
    }, 200);
  }

  componentDidMount = () => {
    Commons.checkAuthentication(this, this.$f7router);
    if (RaApiAuth.isAuthenticated()) {

      switch (RaStorage.getProfile().Availability) {
        case AVAILABILITY.ONLINE:
          this.setState({ userOnline: true });
          break;
        case AVAILABILITY.OFFLINE:
          this.setState({ userOnline: false });
          break;
        default:
          break;
      }

      if (RaStorage.getProfile().PaidService === true) {

        let self = this;
        RaApiProducts.getProducts()
          .then(function (response) {
            if (response.result.success === true)
              self.setState({ products: response.data });
            else
              console.error(response.result.message);
          })
          .catch(function (response) {
            console.error(response.message);
          });
      }

    }
  }

  checkWalletInformations = () => {
    let profile = RaStorage.getProfile();
    let firstSubscriptionDate = profile ? profile.FirstSubscriptionDate : null;
    let serviceMinutesLeft = 0;
    let serviceUsagePerc = 0;
    let serviceUsage = profile ? profile.ServiceUsageMinutes : 0;

    if (profile) {
      if (profile.PurchasedMinutes != null && profile.ServiceUsageMinutes != null)
        serviceMinutesLeft = profile.PurchasedMinutes - profile.ServiceUsageMinutes;
      if (profile.ServiceUsageMinutes != null && profile.PurchasedMinutes != null)
        serviceUsagePerc = profile.ServiceUsageMinutes / profile.PurchasedMinutes;
      if (isNaN(serviceUsagePerc))
        serviceUsagePerc = 0;
      if (profile.ServiceUsageMinutes != null)
        serviceUsage = profile.ServiceUsageMinutes;
    }


    if (firstSubscriptionDate !== this.state.firstSubscriptionDate
      || serviceMinutesLeft !== this.state.serviceMinutesLeft
      || serviceUsagePerc !== this.state.serviceUsagePerc
      || serviceUsage !== this.state.serviceUsage) {
      this.setState({ firstSubscriptionDate: firstSubscriptionDate, serviceMinutesLeft: serviceMinutesLeft, serviceUsage: serviceUsage, serviceUsagePerc: serviceUsagePerc });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    Commons.checkAuthentication(this, this.$f7router);
    //  if (!this.state.firstSubscriptionDate)
    this.checkWalletInformations();
  }

  switchUserStatus = (availability) => {
    if ((this.state.userOnline && availability === AVAILABILITY.OFFLINE) ||
      (!this.state.userOnline && availability === AVAILABILITY.ONLINE)) {
      RaApiContacts.updateAvailability(availability)
        .then(function (response) {

          if (response.result.success === true) {
            successFn(response.data);
          } else {
            errorFn(response.result.message);
          }
        })
        .catch(function (response) {
          errorFn(response.message);
        });
    }

    const errorFn = (errorMsg) => {
      RaLog.log(errorMsg);
    }

    const successFn = (profile) => {

      switch (profile.Availability) {
        case AVAILABILITY.ONLINE:
          this.setState({ userOnline: true });
          break;
        case AVAILABILITY.OFFLINE:
          this.setState({ userOnline: false });
          break;
        default:
          break;
      }
    }
  }
};


// #region Redux
const mapStateToProps = state => {
  return {
    profile: state.remoteAssistance.profile,
    appCompany: state.remoteAssistance.appCompany
  };
};

const mapDispatchToProps = dispatch => {
  return {
    //setToken: (token) => dispatch(actions.setToken(token)),
  }
};

// #endregion

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default connect(mapStateToProps, mapDispatchToProps)(Profile);