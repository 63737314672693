
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    available: "Available",
    unavailable: "Unavailable",
    status: "Status",
    groups: "Groups",
    languages: "Languages",
    logout_button: "Logout",
    logout_alert_title: "Logout",
    logout_alert_text: "Do you want to logout?",
    wallet_checkout_review_message: "Review your order",
    wallet_checkout_product: "Product",
    wallet_checkout_price: "Price",
    wallet_checkout_card: "Card",
    wallet_checkout_close: "Close",
    wallet_checkout_confirm: "PLACE YOUR ORDER",
    wallet_checkout_agreement: "By placing your order, you agree to {0}'s {1} notice and {2}.",
    wallet_checkout_privacy: "privacy",
    wallet_checkout_conditions: "conditions of use",
    wallet_transaction_alert_success_title: "Perfect!",
    wallet_transaction_alert_success_text: "The transaction has been successfully processed",
    wallet_transaction_alert_error_title: "Ops...",
    wallet_transaction_alert_error_text: "Problem: ",
    wallet_transaction_alert_detail_error_text: "Payment failed, check the data entered",
    wallet_title: "Wallet",
    wallet_mins_left: "mins left",
    wallet_of_usage: "of usage",
    wallet_mins: "mins",
    wallet_buy_message: "Buy some minutes of service",
    wallet_choose_message: "Please choose...",
    wallet_add_payment_text: "ADD PAYMENT METHOD",
  },
  it: {
    available: "Disponibile",
    unavailable: "Non disponibile",
    status: "Stato",
    groups: "Gruppi",
    languages: "Lingue parlate",
    logout_button: "Disconnetti",
    logout_alert_title: "Disconnetti",
    logout_alert_text: "Vuoi disconnetterti?",
    wallet_checkout_review_message: "Verifica il tuo ordine",
    wallet_checkout_product: "Prodotto",
    wallet_checkout_price: "Prezzo",
    wallet_checkout_card: "Carta",
    wallet_checkout_close: "Chiudi",
    wallet_checkout_confirm: "CONFERMA L'ORDINE",
    wallet_checkout_agreement: "Confermando l'ordine, dichiari di aver preso visione dell'{1} e accetti integralmente le {2} di {0}.",
    wallet_checkout_privacy: "informativa sulla privacy",
    wallet_checkout_conditions: "condizioni di utilizzo",
    wallet_transaction_alert_success_title: "Perfetto!",
    wallet_transaction_alert_success_text: "La transazione è avvenuta correttamente",
    wallet_transaction_alert_error_title: "Ops...",
    wallet_transaction_alert_error_text: "Problema: ",
    wallet_transaction_alert_detail_error_text: "Pagamento non avvenuto, verificare i dati inseriti",
    wallet_title: "Wallet",
    wallet_mins_left: "minuti residui",
    wallet_of_usage: "di utilizzo",
    wallet_mins: "minuti",
    wallet_buy_message: "Acquista minuti di servizio",
    wallet_choose_message: "Scegli un pacchetto...",
    wallet_add_payment_text: "ACQUISTA",
  }
});
  
  export default localeStrings;