
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    no_calls: "No calls to display",
    list_error: 'Oops...something went wrong',
    placeholder_message: 'Message',
    placeholder_add_comment: 'Add comment or Send',
    message_send_error_alert_text: 'an error occurred sending your message',
    message_send_error_alert_title: 'Oops',
    file_big_alert_text: 'the selected file exceeds the allowed limit of ',
    loadingMessages: 'loading messages...',
    loadingMessagesError: 'oops...an error occurred retrieving messages, please try close and open the chat again',
    fileNotFound: 'File not available',
    fileDownloadError: 'An error an error occurred retrieving this file',
  },
  it: {
    no_calls: "Non hai alcuna chiamata",
    list_error: 'Oops...qualcosa è andatp storto',
    placeholder_message: 'Messaggio',
    placeholder_add_comment: 'Aggiungi un commento o invia',
    message_send_error_alert_text: 'si è verificato un problema nell\'invio del messaggio',
    message_send_error_alert_title: 'Oops',
    file_big_alert_text: 'il file supera la dimensione massima di ',
    loadingMessages: 'sto caricando i messaggi...',
    loadingMessagesError: 'oops...si è verificato un errore nella lettura dei messaggi, prova a chiudere e riaprire la chat',
    fileNotFound: 'Il file non è disponibile, probabilmente è stato rimosso',
    fileDownloadError: 'Si è verificato un problema ad accedere al file',
  }
});
  
  export default localeStrings;