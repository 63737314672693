import React from 'react';
import { List, ListInput, BlockTitle} from 'framework7-react';
import { connect } from 'react-redux';
import AppPageComponent from '../../components/commons/app-page-component';
import {RaMedia} from 'pikkart-remote-assistance';
import localeStrings from './settings-form-local';
import OutputAudioOptions from './outputaudio-options';
import InputAudioOptions from './inputaudio-options';
import InputVideoOptions from './inputvideo-options';
import InputVideoPreview from './inputvideo-preview';
import InputArVideoToggle from './inputarvideo-toggle';
import InputArVideoListItem from './inputarvideo-listitem';

class SettingsForm extends AppPageComponent {


  constructor(props) {
    super(props);

    this.state = {
      
    };
  }


  render() {

    let videoPreview = null;
   /* if (this.props.callContext) {
      videoPreview = this.props.activeCall ? <InputVideoCallPreview slot="content" ></InputVideoCallPreview> : null;
    }
    else {*/
      videoPreview = <InputVideoPreview slot="content" ></InputVideoPreview>;
    //}


    return (
      <div>
        <BlockTitle>{localeStrings.videoTitle}</BlockTitle>
        <List mediaList>

          <ListInput
            label={localeStrings.inputVideoLabel}
            type="select"
            id="video_input"
            defaultValue={RaMedia.getSelectedVideoInputDevice()? RaMedia.getSelectedVideoInputDevice().deviceId : null}
            onChange={() => { 
              RaMedia.selectVideoInputDeviceId(this.$$('#video_input select').val()); 
            }}
          >
            <InputVideoOptions containerSelector="#video_input select" excludeArSelectedDevice={this.props.callContext}></InputVideoOptions>
            {videoPreview}
          </ListInput>

          <InputArVideoToggle callContext={this.props.callContext}></InputArVideoToggle>
          <InputArVideoListItem callContext={this.props.callContext}></InputArVideoListItem>
        </List>
        <BlockTitle>{localeStrings.audioTitle}</BlockTitle>
        <List mediaList>
          <ListInput
            label={localeStrings.inputAudioLabel}
            type="select"
            id="audio_input"
            defaultValue={RaMedia.getSelectedAudioInputDevice()? RaMedia.getSelectedAudioInputDevice().deviceId : null}
            onChange={() => { 
              RaMedia.selectAudioInputDeviceId(this.$$('#audio_input select').val()); 
            }}
          >
            <InputAudioOptions containerSelector="#audio_input select"></InputAudioOptions>
          </ListInput>

          <ListInput
            label={localeStrings.outputAudioLabel}
            type="select"
            id="audio_output"
            defaultValue={RaMedia.getSelectedAudioOutputDevice()? RaMedia.getSelectedAudioOutputDevice().deviceId : null}
            onChange={() => { 
              RaMedia.selectAudioOutputDeviceId(this.$$('#audio_output select').val()); 
            }}
          >
            <OutputAudioOptions containerSelector="#audio_output select"></OutputAudioOptions>
          </ListInput>

        </List>
      </div>
    );
  }

  componentDidMount = () => {
    this.selectArVideoValue();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.selectArVideoValue();
  }

  selectArVideoValue = () => {
    if (this.$$('#arvideo_input select').length > 0) {
      let raVal = RaMedia.getSelectedArVideoInputDevice() ? RaMedia.getSelectedArVideoInputDevice().deviceId : null;
      this.$$('#arvideo_input select').val(raVal);
    }
  }
};

// #region Redux
const mapStateToProps = state => {
  return {
    activeCall: state.remoteAssistance.activeCall,
  };
};

const mapDispatchToProps = dispatch => {
  return {

  }
};

// #endregion

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default connect(mapStateToProps, mapDispatchToProps)(SettingsForm);