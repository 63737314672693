
import LocalizedStrings from 'react-localization';

const appLocaleStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    no_contents: "No contents found",
    today: "Today",
    yesterday: "Yesterday",
    callResult_ErrorTitle: 'Unable to call',
    callResult_TargetOccupied: ' is occupied at the moment.',
    callResult_NoTargetAvailable: ' is offline at the moment.',
    callResult_userUnavailable: 'You are not allowed to call when you are unavailable, please check your profile status.',
    callResult_subscriptionRequired: 'Please check your subscription.',
  },
  it: {
    no_contents: "Nessun contenuto trovato",
    today: "Oggi",
    yesterday: "Ieri",
    callResult_ErrorTitle: 'Chiamata non effettuata',
    callResult_TargetOccupied: ' è occupato al momento.',
    callResult_NoTargetAvailable: ' non è disponibile al momento.',
    callResult_userUnavailable: 'Non puoi effettuare chiamate quando sei in stato \'Non disponibile\', controlla il tuo profilo.',
    callResult_subscriptionRequired: 'Non risulta possibile effettuare la chiamata, controlla la tua iscrizione al servizio.',
  }
});
  
  export default appLocaleStrings;