import React from 'react';
import localeStrings from './error-boundary-local';
import { Page, Block, Icon, Button } from 'framework7-react';
import { RaApiCalls, RaLog } from 'pikkart-remote-assistance';


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service

    if (RaApiCalls.getActiveCall()) {
      RaApiCalls.closeActiveCall();
    }

    RaLog.sendLog(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {

      // You can render any custom fallback UI
      return (<Page pageContent={false} colorTheme="custom">
              
              <Block style={{textAlign: 'center'}}>
                <div>
                  <Icon size="50px" ios="f7:exclamationmark_triangle" aurora="f7:exclamationmark_triangle" md="material:sentiment_very_dissatisfied"></Icon>
                  <h1>{localeStrings.title}</h1>
                  <p>{localeStrings.text}</p>
                  <Button fill style={{display: 'inline-block', marginTop: '20px'}}
                    href='/'
                    reloadAll={true}
                    external={true}
                    view={'.view-main'}>
                    <Icon ios="f7:arrow_clockwise" aurora="f7:arrow_clockwise" md="material:refresh"></Icon>
                    <span style={{marginLeft:'10px'} }>{localeStrings.button}</span>
                  </Button>
                </div>
              </Block>

            </Page>);
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
