import React from 'react';
import { MessagesTitle, Messages, Message, Messagebar, Link, Icon, Progressbar, MessagebarAttachment, MessagebarSheet, MessagebarSheetImage, MessagebarAttachments, PageContent } from 'framework7-react';
import { connect } from 'react-redux';
import { Ra, RaApiRooms, RaApiRoomsMessages, RaApiCallsMessages, RaApiDocuments, RaLog } from 'pikkart-remote-assistance';
import moment from 'moment-timezone';
import Commons from '../../Commons';
import localeStrings from './chat-messages-local';
import { isIOS } from 'react-device-detect';

class ChatMessages extends React.Component {

    constructor(props) {
        super(props);

        this.defaulState = {
            attachments: [],
            sheetVisible: false,
            typingMessage: null,
            messagesData: null,
            images: [],
            people: [],
            answers: [],
            responseInProgress: false,
            dataTrack: null,
            shareDocsVisible: true,
            allowInfinite: props.scrollEnabled,
            showPreloader: props.scrollEnabled,
            scrollMessages: props.scrollEnabled,
            messagesCompleted: false,
        }

        this.state = this.defaulState;

        this.sendingMessage = false;
        this.messagesPerPage = Ra.getConfig().messagesPerPage;
        this.messagesLimit = 500;
        this.loadingMessages = { roomId: this.props.roomId, loading: false };
        this.loadingMoreScheduled = false;
    }


    render() {


        let lastMessageDate = null;
        let messagesContent = [];
        if (this.state.messagesData) {
            this.state.messagesData.forEach((message, index) => {
                let messageDate = new Date(message.date).setHours(0, 0, 0, 0);
                if (messageDate !== lastMessageDate) {
                    messagesContent.push(<MessagesTitle key={"title_" + index}>{Commons.getDateDescription(message.date, "Europe/Rome", "DD/MM/YYYY : HH:mm")}</MessagesTitle>);
                }
                messagesContent.push(<Message
                    id={this.getMessageId(message.uniqueId)}
                    key={"message_" + index}
                    type={message.type}
                    image={message.image}
                    name={message.name}
                    avatar={message.avatar}
                    first={this.isFirstMessage(message, index)}
                    last={this.isLastMessage(message, index)}
                    tail={this.isTailMessage(message, index)}
                    footer={moment.utc(message.date).tz("Europe/Rome").format("HH:mm")}
                    onClickText={() => { this.messageClicked(message); }}
                >
                    {message.text && (
                        <span slot="text" dangerouslySetInnerHTML={{ __html: message.text }} />
                    )}
                </Message>);

                lastMessageDate = messageDate;
            });
        }


        let preloader = null;
        if (this.props.scrollEnabled && this.state.showPreloader && !this.state.messagesCompleted
            && this.state.messagesData !== null) {
            preloader = (<div className='infinite-scroll-preloader' style={{ textAlign: 'center', width: '70%' }}>
                <p>{localeStrings.loadingMessages}</p>
                <Progressbar infinite></Progressbar>
            </div>);
        }
        else if (this.state.messagesData === null) {
            preloader = (<div style={{ textAlign: 'center', width: '100%', marginTop: '40%' }}>
                <div style={{ width: '70%', marginLeft: '15%' }}>
                    <p>{localeStrings.loadingMessages}</p>
                    <Progressbar infinite></Progressbar>
                </div></div>);
        }

        const embeddedMessage = (

            <Messages ref={(el) => { this.messagesComponent = el }}
                scrollMessagesOnEdge={this.props.scrollEnabled}
                scrollMessages={this.state.scrollMessages}>

                {preloader}
                {messagesContent}
                {this.state.typingMessage && (
                    <Message
                        type="received"
                        typing={true}
                        first={true}
                        last={true}
                        tail={true}
                        header={`${this.state.typingMessage.name} is typing`}
                        avatar={this.state.typingMessage.avatar}
                    ></Message>
                )}
            </Messages>);

        const embeddedMessageBar = (
            <Messagebar style={{ position: 'fixed' }}
                placeholder={this.placeholder}
                ref={(el) => { this.messagebarComponent = el }}
                attachmentsVisible={this.attachmentsVisible}
                sheetVisible={this.state.sheetVisible}
            >

                <div className="list-block inputs-list theme-indigo" slot="inner-start" style={{ marginLeft: '5px', marginRight: '10px', display: this.state.shareDocsVisible ? 'inherits' : 'none' }}>
                    <div className="item-content">
                        <div className={"item-media color-indigo fileUpload " + (isIOS ? "" : "button button-raised button-fill ")}>
                            <Icon className="ico_upload" ios="f7:arrow_up_doc" aurora="f7:arrow_up_doc" md="material:attach_file"></Icon>
                            <input id={this.getAttachmentId(this)} type="file" className="upload" />
                        </div>
                    </div>
                </div>
                <Link id="sendMessageButton"
                    iconIos="f7:arrow_up_fill"
                    iconAurora="f7:arrow_up_fill"
                    iconMd="material:send"
                    slot="inner-end"
                    onClick={this.sendMessageToCms.bind(this)}
                ></Link>
                <div className="preloader" id="sendMessageLoader" style={{ display: 'none', paddingLeft: '16px', paddingRight: '16px', maxHeight: '24px', maxWidth: '24px' }}></div>
                <MessagebarAttachments>
                    {this.state.attachments.map((image, index) => (
                        <MessagebarAttachment
                            key={index}
                            image={image}
                            onAttachmentDelete={() => this.deleteAttachment(image)}
                        ></MessagebarAttachment>
                    ))}
                </MessagebarAttachments>
                <MessagebarSheet>
                    {this.state.images.map((image, index) => (
                        <MessagebarSheetImage
                            key={index}
                            image={image}
                            checked={this.state.attachments.indexOf(image) >= 0}
                            onChange={this.handleAttachment.bind(this)}
                        ></MessagebarSheetImage>
                    ))}
                </MessagebarSheet>
            </Messagebar>
        );

        return (
            <PageContent style={{ paddingBottom: '48px' }}
                infinite={this.props.scrollEnabled && this.state.allowInfinite && !this.state.messagesCompleted}
                infiniteTop={this.props.scrollEnabled}
                infiniteDistance={50}
                infinitePreloader={false}/*{this.props.scrollEnabled && this.state.showPreloader && !this.state.messagesCompleted}*/
                onInfinite={this.scheduleLoadingMore}
                messagesContent={true}>
                {embeddedMessage}
                {this.props.sendMessageEnabled && (
                    embeddedMessageBar
                )}
            </PageContent>
        )
    }

    scheduleLoadingMore = () => {
        const self = this;
        if (self.props.isPageAfterIn()
            && self.loadingMoreScheduled === false) {
            //      this.loadMore.bind(this);
            self.loadingMoreScheduled = true;
            setTimeout(() => {
                self.loadMore();
                self.loadingMoreScheduled = false;
            }, 500);
        }
    }

    messagesLoadable = (self) => {
        return !self.loadingMessages || self.loadingMessages.loading === false || self.loadingMessages.roomId !== this.props.roomId;
    }

    setMessagesLoadable = (self, loadable) => {
        self.loadingMessages = { loading: !loadable, roomId: self.props.roomId };
    }

    loadMore() {
        if (!this.props.scrollEnabled) { return; }
        const self = this;
        if (self.messagesLoadable(self)) {


            if (self.state.messagesCompleted === true) return;
            if (self.state.messagesData && self.state.messagesData.length === 0) return;
            if (!self.props.roomId) return;
            if (!self.state.allowInfinite) return;
            self.setState({
                allowInfinite: false,
                scrollMessages: false
            });

            self.setMessagesLoadable(self, false);

            RaApiRoomsMessages.getMessages(self.props.roomId,
                self.state.messagesData && self.state.messagesData.length > 0 ? self.state.messagesData[0].uniqueId : null)
                .then(response => {
                    const items = self.state.messagesData ? self.state.messagesData : [];
                    if (items.length >= self.messagesLimit
                        || response.result.success === false) {
                        self.setState({ showPreloader: false });
                        return;
                    }

                    if (response.result.success === true) {
                        let unreadMessagesExists = false;
                        let messages = response.data;//.reverse();
                        for (let i = 0; i < messages.length; i++) {
                            let message = messages[i];
                            //  items.push(self.getMessageDataFromCmsMessage(message));
                            items.splice(0, 0, self.getMessageDataFromCmsMessage(message));
                            if (!message.Read) {
                                unreadMessagesExists = true;
                            }
                        }

                        if (unreadMessagesExists) {
                            RaApiRooms.markMessagesAsRead(self.props.roomId);
                            if (self.props.messagesMarkedAsReadHandler) {
                                self.props.messagesMarkedAsReadHandler(self.props.roomId);
                            }
                        }
                    }

                    self.setState({
                        messagesData: items,
                        allowInfinite: true,
                        messagesCompleted: response.result.success === true && response.data.length < self.messagesPerPage ? true : false,
                    });
                }).catch().then(() => {

                    setTimeout(() => {
                        self.setMessagesLoadable(self, true);
                    }, 3000);
                })
        }
    }

    get attachmentsVisible() {
        const self = this;
        return self.state.attachments.length > 0;
    }
    get placeholder() {
        const self = this;
        return self.state.attachments.length > 0 ? localeStrings.placeholder_add_comment : localeStrings.placeholder_message;
    }

    getMessageId = (messageId) => {
        return 'message_' + messageId;
    }

    getAttachmentId = (self) => {
        return self.props.attachmentElementId + '_selectedFile';
    }

    scrollToLastMessage = () => {
        Commons.scrollTo(document.getElementsByClassName('messages-content')[0], document.getElementsByClassName('messages-content')[0].scrollHeight, 500);
        //document.getElementsByClassName('messages-content')[0].scrollTo(0, document.getElementsByClassName('messages-content')[0].scrollHeight);
    }

    initMessageBar = (self) => {

        var oldVal;

        self.$$('.messagebar textarea').on('change textInput input', function () {
            var val = this.value;
            if (val !== oldVal) {
                oldVal = val;
                self.scrollToLastMessage();
            }
        });

        self.$$('.messagebar textarea').on('focus', function () {
            setTimeout(() => {
                self.scrollToLastMessage();
            }, 500);
        });
    }

    componentDidMount() {
        const self = this;
        self.$f7ready(() => {
            if (self.messagebarComponent) {
                self.messagebar = self.messagebarComponent.f7Messagebar;
                self.messages = self.messagesComponent.f7Messages;
                self.initMessageBar(self);
            }

            if (typeof window.FileReader !== 'function') {
                RaLog.log("The file API isn't supported on this browser yet.");
                this.setState({ shareDocsVisible: false });
            }
            else {
                self.$$('#' + self.getAttachmentId(self)).on('change', function () {
                    let input = self.$$('#' + self.getAttachmentId(self))[0];
                    if (!input.files) {
                        RaLog.log("This browser doesn't seem to support the `files` property of file inputs.");
                        self.setState({ shareDocsVisible: false });
                    }
                    else if (!input.files[0]) {
                        RaLog.log("No file selected");
                    }
                    else {
                        let file = input.files[0];
                        if (file.size > (parseInt(process.env.REACT_APP_MAX_FILES_UPLOAD_MB_LENGTH) * 1024 * 1024)) {
                            let message = localeStrings.file_big_alert_text + process.env.REACT_APP_MAX_FILES_UPLOAD_MB_LENGTH + ' MB';
                            Commons.showErrorMessage(self, message);
                        }
                        else {

                            self.toBase64(file).then((base64) => {
                                self.sendMessageToCms(null, file.name, { fileName: file.name, base64: base64 });
                            });
                        }
                    }

                });
            }

            if (self.props.callId) {
                self.getCallMessages(self.props.callId);
            }
            else if (self.props.roomId) {
                self.getRoomMessages(self.props.roomId);
            }
        });

        RaApiRooms.onIncomingRoomMessage = (message) => {
            if (self.props.roomId && self.props.roomId === message.RoomUniqueId) {

                message.Read = true;
                RaApiRooms.markMessagesAsRead(message.RoomUniqueId);
                self.setState({
                    messagesData: [...self.state.messagesData, ...[self.getMessageDataFromCmsMessage(message)]]
                });
            }
        }
    }

    toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    getMessageDataFromCmsMessage = (message) => {
        let messageData = null;
        if (this.props.profile && this.props.profile.UniqueId === message.Sender.UniqueId) { //sono il mittente del messaggio
            messageData = {
                date: message.Date,
                uniqueId: message.UniqueId,
                sender: message.Sender,
                type: 'sent',
                text: message.Message,
                attachName: message.AttachmentName,
            };
        }
        else {
            messageData = {
                date: message.Date,
                uniqueId: message.UniqueId,
                sender: message.Sender,
                type: 'received',
                text: message.Message,
                name: message.Sender.Name,
                attachName: message.AttachmentName,
            };
        }
        return messageData;
    }

    copyMessageData = (messageData, type) => {
        return {
            date: messageData.date,
            uniqueId: messageData.uniqueId,
            sender: messageData.sender,
            type: type,
            text: messageData.text,
            attachName: messageData.attachName
        }
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (this.props.roomId && this.props.roomId !== prevProps.roomId) {
            this.setMessagesLoadable(this, true);//nche se sto caricando dei messaggi, se cambio stanza li devo poter ricaricare subito
            this.setState(this.defaulState, () => {
                setTimeout(() => { this.getRoomMessages(this.props.roomId); }, 500);
            });
        }


        else if (this.props.callId &&
            this.props.dataTrack !== null && this.props.dataTrack !== prevProps.dataTrack) {
            //RaLog.log('chat data track' + this.props.dataTrack);
            const jsonDataTrack = JSON.parse(this.props.dataTrack);
            if (jsonDataTrack.type === 'msg') {
                let messages = this.state.messagesData;
                const arrivedMessage = RaApiCallsMessages.GetDecryptedMessage(jsonDataTrack.data);

                const messagesToSee = [];
                messages.forEach((message) => {
                    if (this.props.profile.UniqueId === message.sender.UniqueId) { //sono il mittente del messaggio
                        messagesToSee.push(this.copyMessageData(message, 'sent'));
                    }
                    else {
                        messagesToSee.push(this.copyMessageData(message, 'received'));
                    }
                });

                messagesToSee.push(this.getMessageDataFromCmsMessage(arrivedMessage, 'received'));

                this.setState({
                    // Reset attachments
                    attachments: [],
                    // Hide sheet
                    sheetVisible: false,
                    // Send message
                    messagesData: [...messagesToSee],
                });
            }
        }

        if (this.props.callId && this.props.callId !== prevProps.callId) {
            this.setState(this.defaulState, () => {
                setTimeout(() => { this.getCallMessages(this.props.callId) }, 500);
            });
        }
    }

    setMessageList = (self, isCall, messages) => {

        let unreadMessagesExists = false;
        const messagesToSee = [];
        messages.reverse().forEach((message) => {
            messagesToSee.push(self.getMessageDataFromCmsMessage(message));

            if (!isCall && !message.Read) {
                unreadMessagesExists = true;
            }
        });

        if (!isCall && unreadMessagesExists) {
            RaApiRooms.markMessagesAsRead(self.props.roomId);
            if (self.props.messagesMarkedAsReadHandler) {
                self.props.messagesMarkedAsReadHandler(self.props.roomId);
            }
        }

        self.setState({
            // Reset attachments
            attachments: [],
            // Hide sheet
            sheetVisible: false,
            // Send message
            messagesData: self.state.messagesData ? [...self.state.messagesData, ...messagesToSee] : messagesToSee,
            messagesCompleted: messages.length < self.messagesPerPage ? true : false,
        }, () => {
            setTimeout(() => {
                self.$$('.messagebar textarea').focus();
            }, 500);
        });
    }

    getRoomMessages = (roomId) => {

        const self = this;
        if (self.messagesLoadable(self)) {

            self.setMessagesLoadable(self, false);
            RaApiRoomsMessages.getMessages(roomId).then(response => {

                if (response.result.success === true) {
                    self.setMessageList(self, false, response.data);
                }
                else {
                    Commons.showErrorMessage(self, localeStrings.loadingMessagesError);
                }
            })
                .then().catch().then(() => {

                    setTimeout(() => {
                        self.setMessagesLoadable(self, true);
                    }, 3000);
                });
        }
    }

    getCallMessages = (callId) => {

        const self = this;

        RaApiCallsMessages.getMessages(callId).then(response => {

            if (response.result.success === true) {
                self.setMessageList(self, true, response.data);
            }
        });
    }

    isFirstMessage(message, index) {
        const self = this;
        const previousMessage = self.state.messagesData[index - 1];
        if (message.isTitle) return false;
        if (!previousMessage || previousMessage.type !== message.type || previousMessage.name !== message.name) return true;
        return false;
    }
    isLastMessage(message, index) {
        const self = this;
        const nextMessage = self.state.messagesData[index + 1];
        if (message.isTitle) return false;
        if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name) return true;
        return false;
    }
    isTailMessage(message, index) {
        const self = this;
        const nextMessage = self.state.messagesData[index + 1];
        if (message.isTitle) return false;
        if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name) return true;
        return false;
    }
    deleteAttachment(image) {
        const self = this;
        const attachments = self.state.attachments;
        const index = attachments.indexOf(image);
        attachments.splice(index, 1);
        self.setState({ attachments });
    }
    handleAttachment(e) {
        const self = this;
        const attachments = self.state.attachments;
        const index = self.$$(e.target).parents('label.checkbox').index();
        const image = self.state.images[index];
        if (e.target.checked) {
            // Add to attachments
            attachments.unshift(image);
        } else {
            // Remove from attachments
            attachments.splice(attachments.indexOf(image), 1);
        }
        self.setState({ attachments });
    }

    removeMessageFooter = (self, messageId) => {

        let messageDiv = document.getElementById(self.getMessageId(messageId));
        if (messageDiv) {
            let messageBubble = messageDiv.getElementsByClassName('message-bubble');
            if (messageBubble.length > 0) {

                let messageFooter = messageBubble[0].getElementsByClassName('message-text-footer');
                if (messageFooter.length > 0) {
                    messageFooter[0].remove();
                }
            }
        }
    }

    setMessageFooter = (self, messageId, text) => {

        let messageDiv = document.getElementById(self.getMessageId(messageId));
        if (messageDiv) {
            let messageBubble = messageDiv.getElementsByClassName('message-bubble');
            if (messageBubble.length > 0) {

                let messageFooter = messageBubble[0].getElementsByClassName('message-text-footer');
                if (messageFooter.length > 0) {
                    messageFooter[0].innerText = text;
                }
                else {
                    messageBubble[0].insertAdjacentHTML('beforeend', '<div class="message-text-footer">' + text + '</div>');
                }
            }
        }
    }

    messageClicked(message) {
        let self = this;
        RaLog.log('messageClicked', message);
        if (message.attachName) {

            RaLog.log('is attachment');
            self.setMessageFooter(self, message.uniqueId, 'downloading...');

            RaApiDocuments.openStorageFile(message.attachName)
                .then((result) => {
                    self.removeMessageFooter(self, message.uniqueId);
                    if (result.success === false) {

                        if (result.code === 404) {
                            Commons.showErrorMessage(self, localeStrings.fileNotFound);
                        }
                        else {
                            Commons.showErrorMessage(self, localeStrings.fileDownloadError);
                        }
                    }
                });
        }
        else if (Commons.isValidUrl(message.text)) {
            const win = window.open(message.text, '_blank');
            win.focus();
        }
    }

    sendMessageToCms(event, message, file) {

        const self = this;
        if (!message) {
            message = self.messagebar.getValue().replace(/\n/g, '<br>').trim();
            if (message.trim().length === 0) {
                return;
            }
        }
        let button = document.getElementById('sendMessageButton');
        let loader = document.getElementById('sendMessageLoader');
        let attachment = self.$$('#' + self.getAttachmentId(self));
        const showHideLoader = (show) => {

            if (button && loader && attachment) {
                button.style.display = show ? 'none' : 'flex';
                loader.style.display = show ? 'flex' : 'none';
                if (show) {
                    attachment.attr('disabled', 'true');
                }
                else {
                    attachment.removeAttr('disabled');
                    attachment[0].value = '';
                }
            }
        }

        const sendMessageResponseHandler = (response) => {
            RaLog.log(response);
            if (response.result.success === true) {

                const message = self.getMessageDataFromCmsMessage(response.data);

                self.setState({
                    // Reset attachments
                    attachments: [],
                    // Hide sheet
                    sheetVisible: false,
                    // Send message
                    messagesData: self.state.messagesData ? [...self.state.messagesData, ...[message]] : [message],
                    scrollMessages: true,
                });

                self.messagebar.clear();

                // Focus area
                if (response.data.Message.length) self.messagebar.focus();
            }
            else {
                this.$f7.dialog.alert('an error occurred sending your message', 'error');
                RaLog.log(response.result.message);
            }
        }

        if (self.sendingMessage === false) {
            self.sendingMessage = true;
            showHideLoader(true);

            setTimeout(() => {
                if (self.props.roomId) {
                    RaApiRoomsMessages.sendMessage(self.props.roomId, message, file)
                        .then(response => {
                            sendMessageResponseHandler(response);
                        })
                        .finally(() => {
                            showHideLoader(false);
                            self.sendingMessage = false;
                        });
                }
                else {
                    RaApiCallsMessages.sendMessage(message, file)
                        .then(response => {
                            sendMessageResponseHandler(response);
                        })
                        .finally(() => {
                            showHideLoader(false);
                            self.sendingMessage = false;
                        });
                }
            }, 250);
        }
    }
};

// #region Redux
const mapStateToProps = state => {
    return {
        loggedIn: state.remoteAssistance.loggedIn,
        dataTrack: state.remoteAssistance.dataTrack,
        profile: state.remoteAssistance.profile,
        unreadMessages: state.remoteAssistance.unreadMessages,
    };
};

/*const mapDispatchToProps = dispatch => {
    return {
    }
};*/

// #endregion

export default connect(mapStateToProps, null)(ChatMessages);