
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    message_minutes_left: "assistance minutes left",
    call_accept: "Accept",
    call_decline: "Decline",
    incoming_call_text: "Video call incoming...",
  },
  it: {
    message_minutes_left: "minuti di assistenza residui",
    call_accept: "Accetta",
    call_decline: "Rifiuta",
    incoming_call_text: "Video chiamata in arrivo...",
  }
});
  
  export default localeStrings;