import React from 'react';
import { Page, List, Link, Navbar, ListItem, Col, Block, Preloader, ListIndex, ListGroup } from 'framework7-react';
import { connect } from 'react-redux';
import {RaApiContacts, RaLog, CONTACT_TYPE} from 'pikkart-remote-assistance';
import localeStrings from './contacts-list-local';

// import moment from 'moment-timezone';

class ContactsList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      contacts: null,
      errormessage: null,
    };

    this.fetchingContactsList = false;
  }

  render() {

    if (this.state.errormessage !== null) {
      return (
        <Page>
        <Navbar title={localeStrings.title}>
          <Link slot="right" iconMd="material:close" iconIos="f7:xmark" iconAurora="f7:xmark" onClick={(e) => { this.props.closeContacts(); }}></Link>
        </Navbar>
          <Block className="row demo-preloaders align-items-stretch text-align-center">
            <Col>
              {this.state.errormessage}
            </Col>
          </Block>
        </Page>
      );
    }

    if (this.props.userAddressBook === null) {
      return (
        <Page>
        <Navbar title={localeStrings.title}>
          <Link slot="right" iconMd="material:close" iconIos="f7:xmark" iconAurora="f7:xmark" onClick={(e) => { this.props.closeContacts(); }}></Link>
        </Navbar>
          <Block className="row demo-preloaders align-items-stretch text-align-center contacts">
            <Col>
              <Preloader size={42}></Preloader>
            </Col>
          </Block>
        </Page>
      );
    }

    if (this.props.userAddressBook.length === 0) {
      return (
        <Page>
        <Navbar title={localeStrings.title}>
          <Link slot="right" iconMd="material:close" iconIos="f7:xmark" iconAurora="f7:xmark" onClick={(e) => { this.props.closeContacts(); }}></Link>
        </Navbar>
          <Block className="row demo-preloaders align-items-stretch text-align-center">
            <Col>
              {localeStrings.no_contacts}
            </Col>
          </Block>
        </Page>
      );
    }

    return ( // onListIndexSelect={this.onIndexSelect.bind(this)}
      <Page>
         <Navbar title={localeStrings.title}>
           <Link slot="right" iconMd="material:close" iconIos="f7:xmark" iconAurora="f7:xmark" onClick={(e) => { 
             RaLog.log('close click'); 
             this.props.closeContacts(); 
             }}></Link>
         </Navbar>
        
          <ListIndex
            indexes="auto"
            listEl=".list.contacts-list"
            scrollList={true}
            label={true}
          ></ListIndex>
        {((this.props.newRoomContactsListOpen === true && this.props.isRoomContactList === true) || (this.props.newCallContactsListOpen === true && this.props.isCallContactList === true)) ? this.getContacts() : null}
      </Page>

    );
  }

  onIndexSelect(itemContent) {
    //RaLog.log(itemContent);
    this.props.closeContacts(); 
    this.props.backPageClicked(itemContent)
  }


  getContacts = () => {

    if (this.props.userAddressBook === null || this.props.userAddressBook.length === 0) {
      return (null);
    }

    const getGroupContact = (firstLetter) => {
      let contacts = [];
      this.props.userAddressBook.forEach((item) => {
  
        if (item.Name.indexOf(firstLetter) === 0) {
          if (this.props.noGroups !== true  || item.Type === CONTACT_TYPE.USER) {

            contacts.push(<ListItem key={"contact_" + item.UniqueId}
                            onClick={() => { this.onIndexSelect(item); }}
                            title={item.Name}
                            footer={(item.Company != null ? item.Company.Name + (item.CompanyRole? ' - ':''):'') + item.CompanyRole}
                            href="">
                        </ListItem>);
          }
        }
      });

      return contacts;
    }

    let lastLetter = null;
    let groups = [];
    this.props.userAddressBook.forEach((item) => {
      let firstLetter = item.Name.substring(0, 1);
      if (groups.length === 0 || lastLetter !== firstLetter) {

        if (this.props.noGroups !== true  || item.Type === CONTACT_TYPE.USER) {
          groups.push(<ListGroup key={'group_'+firstLetter}>
                        <ListItem title={firstLetter} groupTitle key={'item_'+firstLetter}></ListItem>
                        {getGroupContact(firstLetter)}
                      </ListGroup>);
        }

        lastLetter = firstLetter;
      }
    });


    return (
      <List contactsList className={"searchbar-found contacts-found-"+((this.props.newRoomContactsListOpen === true) ? 'rooms':'calls')}>
        {groups}
      </List>
    );
  }

  componentDidMount = () => {
    
  }

  componentDidUpdate = () => {
    
  }

  fetchContactsList = () => {
    let self = this;

    const errorFn = (errorMsg) => {
      if (errorMsg !== this.state.errormessage) {
        this.setState({
          errormessage: errorMsg,
        });
      }
    }

    const successFn = (contacts) => {
      this.setState({ errormessage: null });
    }


    if (self.fetchingContactsList !== true) {
      self.fetchingContactsList = true;

      RaApiContacts.getAddressBook()
      .then(function (response) {

        if (response.result.success === true) {
          successFn(response.data);
        } else {
          errorFn(response.result.message);
        }
      })
      .catch(function (response) {
        errorFn(response.message);
      })
      .then(() => {
        self.fetchingContactsList = false;
      });
    }

  };

};

// #region Redux
const mapStateToProps = state => {
  return {
    userAddressBook: state.remoteAssistance.userAddressBook,
    newRoomContactsListOpen: state.app.newRoomContactsListOpen,
    newCallContactsListOpen: state.app.newCallContactsListOpen,
  };
};

const mapDispatchToProps = () => {
  return {
    //    setToken: (token) => dispatch(RaReduxActions.setToken(token)),
  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(ContactsList);
