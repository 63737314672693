
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    text: 'Logging out...',
  },
  it: {
    text: 'Disconnessione...',
  }
});
  
  export default localeStrings;