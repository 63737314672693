import React from 'react';
import { connect } from 'react-redux';
import {RaCordovaNet, RaCordova, RaLog, MJPEG} from 'pikkart-remote-assistance';

class IpCamStream extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      
    };

    this.player = null;
  }

  render() { 

    return (<div>
              <canvas id={this.props.canvasId} style={{display: this.state.renderingError?'none':'block'}}></canvas>
            </div>); 
  }

  onStart = () => {
    if (this.props.onStart)
      this.props.onStart();
  }

  onStop = () => {
    if (this.props.onStop)
      this.props.onStop();
  }

  errorHandler = (errorMsg, error) => {
    
    this.setState({renderingError: errorMsg});
    RaLog.error(errorMsg, error);
    if (this.props.setRenderingError)
      this.props.setRenderingError(errorMsg, error);
  }

  play = (ipCam) => {
    RaLog.log("play called");
    if (!this.state.running) {
      RaLog.log("play ip cam");
      
      let self = this;
  /*    self.errorHandler('Please enable your Router WIFI e try again.', 'IPCAM_ROUTER');
      return;*/

      if (this.props.deviceIp) {
        self.executePlay(ipCam, this.props.deviceIp);
      }
      else if (!RaCordova.isCordova()) {
        self.executePlay(ipCam, '192.168.1.117');
      }
      else 
      {
        RaCordovaNet.isWifiHotspotEnabled(function(isEnabled) {
          RaLog.log('isWifiHotspotEnabled:', isEnabled);
          if (!isEnabled) {
            self.errorHandler('Please enable your Router WIFI e try again.', 'IPCAM_ROUTER');
          } 
          else {
   
            RaCordovaNet.getListOfConnectedDevice(function(devices) {
              RaLog.log('getListOfConnectedDevice', devices);
    
              if (devices && devices[0]) {
                self.executePlay(ipCam, devices[0].ip);
              }
              else {
                self.errorHandler('Live Glasses not found...', 'IPCAM_NOTCONNECTED');
              }
            }, function (error) {
              self.errorHandler('Error retrieving Live Glasses IP...', error);
            });
  
          }
        }, function (error) {
          self.errorHandler('Unable to detect your Router WIFI status', error);
        });
      }
    }
  }
  
  executePlay = (ipCam, deviceIp) => {
    const self = this;

    const playerOptions = {
      
      onStart: () => {
        RaLog.log('player.onStart');
        if (self.props.ipCamConnecting) {
          self.props.ipCamConnecting();
        }
      },
      onStarted : (srcSize) => {
        RaLog.log('player.onStarted', srcSize);
        if (self.props.ipCamConnected) {
          self.props.ipCamConnected();
        }
        if (self.props.setIpCamStreamDimensions) {
          self.props.setIpCamStreamDimensions(srcSize);
        }
      },
      onError: (e) => {
        RaLog.log('player.onError');
        self.errorHandler(e);
      },
    };

    this.player = new MJPEG.Player(this.props.canvasId, ipCam.StreamRequest.Url.replace('<IP>', deviceIp), playerOptions);
    this.player.start();

    if (this.onStart) {
      this.onStart();
    }
    this.setState({running:true, renderingError:null}); 
  }

  stop = () => {
    RaLog.log('stop called');
    if (this.state.running) {
      RaLog.log('stop ip cam');

      this.player.stop();
      if (this.onStop) {
          this.onStop();
      }
      this.setState({running:false, renderingError:null});
    }
  }

  componentDidMount = () => {
    if (this.props.selectVideoInputIpCam)
      this.play(this.props.selectVideoInputIpCam);
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (this.props.selectVideoInputIpCam)
    {
      if (!prevProps.selectVideoInputIpCam)
        this.play(this.props.selectVideoInputIpCam);
      else if (this.props.selectVideoInputIpCam.Code !== prevProps.selectVideoInputIpCam.Code)
      {
        this.stop();
        this.play(this.props.selectVideoInputIpCam);
      }

    }
    else if (prevProps.selectVideoInputIpCam) {
      this.stop();
    }
  }
  
  componentWillUnmount = () => {
    this.stop();
  }
}

// #region Redux
const mapStateToProps = state => {
  return {
      selectVideoInputIpCam: state.remoteAssistance.selectVideoInputIpCam
  };
};

const mapDispatchToProps = dispatch => {
  return {
    
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(IpCamStream);