import React from 'react';
import { RaApiAuth, RaApiCalls, RaCrypto, Ra, RaApiRooms, RaUI, RaLog, RaCordova, LOG_TYPE } from 'pikkart-remote-assistance';
import { Page, Block, Col, Preloader } from 'framework7-react';
import { connect } from 'react-redux';
import { initializedFirebaseApp } from "../init-fcm";
import * as actions from '../redux/actions';
import localeStrings from './landing-local';
import moment from 'moment-timezone';
import 'moment/locale/it';    
import {isChrome, isIOS} from 'react-device-detect';

class Landing extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      browserCheck: false,
      browserError: false
    };
  }

  render() {

    let preloader = null;
    let label = null;
    let subtitle = null;
    if (this.state.browserCheck) {
      label = localeStrings.checkingBrowserLabel;
      subtitle = localeStrings.checkingBrowserLabelSubtitle;
      preloader = <Preloader size={28} style={{marginTop: '30px'}}></Preloader>;
    }
    else if (this.state.browserError) {
      label = localeStrings.checkingBrowserError;
      subtitle = localeStrings.checkingBrowserErrorSubtitle;
    }
    

    return <Page>
            <Block className="row demo-preloaders align-items-stretch text-align-center spinner">
              <Col>
                <h1>{label}</h1>
                <p>{subtitle}</p>
                {preloader}
              </Col>
            </Block>
          </Page>;

  }

  initRemoteAssistance = () => {
    return new Promise((resolve, reject) => {

      moment().locale(localeStrings.getLanguage());
      
      /*********************************
       * INIZIALIZZO LA LIBRERIA DI REMOTE ASSISTANCE
       ********************************/

      Ra.onCordovaReady = () => {
        RaLog.log('APP Ra.onCordovaReady');

        RaUI.lockOrientation();

        /*********************************
         * TASTO BACK DEL DEVICE
         ********************************/
        this.processingBackbuttonRequest = false;
        const self = this;
        document.addEventListener("backbutton", () => {

          //gestisco un semaforo perché questo componente è utilizzato da tutte le pagine, quindi questo evento viene scatenato n volte contemporaneamente
          //non posso fare diversamente perché l'oggetto router è accessibile solo dalle pagine dichiarate nel router, e questo mi impedisce di fare redirect in AppBoot, Landing o altre pagine centralizzate
          if (self.processingBackbuttonRequest === false) {

            //prima verifico se ci sono popup aperti, in tal caso li chiudo
            let openedPopups = self.$$('.modal-in');
            let openedMenu = self.$$('.panel-in');
            if (openedPopups.length > 0) {
              openedPopups.forEach(popup => {
                self.$f7.popup.close('#'+popup.id, true);
              })
            }
            else if (openedMenu.length > 0) {
              openedMenu.forEach(menu => {
                self.$f7.panel.close('#'+menu.id, true);
              })
            }
            //altrimenti gestisco la navigazione o uscita dall'app
            else {
              self.processingBackbuttonRequest = true;
              if (self.$f7router.currentRoute.url !== "/main/" 
                  && self.$f7router.currentRoute.url !== "/main-calls/"
                  && self.$f7router.currentRoute.url !== "/login/"
                  && self.$f7router.currentRoute.url !== "/incomingcall/") {
                self.$f7router.navigate('/main/', { reloadAll: true } ); 
  
                setTimeout(() => {
                  self.processingBackbuttonRequest = false;
                }, 2000);
              }
              else if (self.$f7router.currentRoute.url === "/main/"
                      || self.$f7router.currentRoute.url === "/login/") {
        
                self.$f7.dialog.confirm(localeStrings.exitAppText, localeStrings.exitAppTitle, () => {
                  navigator.app.exitApp();
                }, () => {
  
                  setTimeout(() => {
                    self.processingBackbuttonRequest = false;
                  }, 2000);
                });
              }  
            }




          }

        }, false);
      }


      RaLog.log('INIT');
      Ra.init({
        appCode: process.env.REACT_APP_CODE,
        customerCode: process.env.REACT_APP_CUSTOMER_CODE,
        apiUrl: process.env.REACT_APP_WS_URL,
        callsEnabled: process.env.REACT_APP_VIDEOCALL_ENABLED === "true",
        maxUsersInCall: process.env.REACT_APP_MAX_USERS_IN_ROOM,
        cryptoSchema: RaCrypto.CRYPTOSCHEMA_JSON_V1,
        initializedFirebaseApp: initializedFirebaseApp,
        firebaseKey: process.env.REACT_APP_FIREBASE_WEB_KEY,
        messagesPerPage: process.env.REACT_APP_CHAT_MESSAGES_PER_PAGE? parseInt(process.env.REACT_APP_CHAT_MESSAGES_PER_PAGE):30,
        arEnabled: process.env.REACT_APP_AR_ENABLED === 'true',
        openCvUrl: '/js/opencv.js',
        /*webRtcAdapterUrl: './js/webrtc-adapter.js',*/
        logType: LOG_TYPE.VERBOSE,
      }).then((notificationMessage) => {resolve(notificationMessage);}).catch(reject);

      /*********************************
       * GESTIONE USCITA DALLA PAGINA
       ********************************/
      window.addEventListener('beforeunload', (event) => {
        if (RaApiCalls.getActiveCall() != null) {
          //event.returnValue = 'There is pending work. Sure you want to leave?';
          RaApiCalls.closeActiveCall();
        }
        window.focus();
      });

      
      /*********************************
       * INGRESSO NELLA PAGINA
       ********************************/

      window.addEventListener('focus', function () {
        if (RaApiAuth.isAuthenticated()) {
          //si verificano chiamate in arrivo
          RaApiCalls.checkIncomingCall()
          .then(() => {

            //si verificano  aggiornamenti sulle chat
            if (RaApiAuth.isAuthenticated() && !RaApiCalls.getActiveCall()) {
              RaApiRooms.getContactRooms()
              .catch(function (errorMessage) {
                //handle error
                console.error(errorMessage);
              });
            }
          })
        }
      });
    });
  }


  componentDidMount = () => {
    if (this.props.appInit === true) {
      return;
    }
    this.props.setAppInit(true);

    let self = this;

    if (!RaCordova.isCordova()) {
     // let browser = self.getBrowser();
      self.setState({browserCheck: true});
      setTimeout(()=>{
        if (!isChrome || isIOS) {
          self.setState({browserError: true, browserCheck: false});
        }
        else {
          self.launchApplication(self);
        }
      }, 1000);
    }
    else {
      self.launchApplication(self);
    }
  }

  launchApplication = (self) =>{
    RaLog.log('launchApplication');

    let notificationMessage = null;
    this.initRemoteAssistance().then((message) => {
      RaLog.log('initRemoteAssistance message', message);
      notificationMessage = message;
    }).catch()
    .finally(() => {

      if (RaApiAuth.isAuthenticated()) {
        RaApiCalls.checkIncomingCall()
        .then()
        .catch(function (response) {
          console.error(response.message);
        })
        .then(() => {

          //aggiorno tutti i dati dell'utente
          RaApiAuth.loadAllUserData().then().catch((errorMsg)=> {
            console.error(errorMsg);
          }).finally();
          
          if (RaApiCalls.getIncomingCall() != null
              && self.$f7router.currentRoute.url !== "/incomingcall/") {
                self.$f7router.navigate('/incomingcall/', {reloadAll: true});
          }
          else if (this.$f7router.currentRoute.url !== "/main/") {
            
            let options = {reloadAll: true, props: {fade: true}};
            if (notificationMessage) {
              options.props.notificationMessage = notificationMessage;
            }

            self.$f7router.navigate('/main/', options);
          } 
        /* else if (this.$f7router.currentRoute.url !== "/splash/") {
            self.$f7router.navigate('/splash/', {reloadAll: true});
          } */
        });
      }
      else {
        console.error(self.props);
        if (self.$f7router.currentRoute.url !== "/login/") {
        //  setTimeout(()=>{
            self.$f7router.navigate('/login/', {reloadAll: true});
        //  }, 300);
        }
      }
    });
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
  }
/*
  getBrowser = () => {
    // Opera 8.0+
    const isOpera = (!!window.opr && !!window['opr'].addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

    // Firefox 1.0+
    const isFirefox = typeof InstallTrigger !== 'undefined';

    // Safari 3.0+ "[object HTMLElementConstructor]" 
    const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof window['safari'] !== 'undefined' && window['safari'].pushNotification));

    // Internet Explorer 6-11
    const isIE = !!document.documentMode;

    // Edge 20+
    const isEdge = !isIE && !!window.StyleMedia;

    // Chrome 1 - 71
    const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    // Blink engine detection
    const isBlink = (isChrome || isOpera) && !!window.CSS;

    return { isOpera: isOpera, isFirefox: isFirefox, isSafari: isSafari, isIE: isIE, isEdge: isEdge, isChrome: isChrome, isBlink: isBlink}
  }*/
};


// #region Redux
const mapStateToProps = state => {
  return {
    appInit: state.app.appInit,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setAppInit: (appInit) => dispatch(actions.setAppInit(appInit)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
