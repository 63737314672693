
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    close_selected_pic: "CLOSE",
  },
  it: {
    close_selected_pic: "CHIUDI",
  }
});
  
  export default localeStrings;