import React from 'react';
import { connect } from 'react-redux';
import {RaMedia} from 'pikkart-remote-assistance';
import localeStrings from './inputvideo-options-local';

class InputVideoOptions extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      
    };
  }


  render() {

    let videoInputDevices = [];
    if (this.props.videoInputDevices && this.props.videoInputDevices.length > 0) {

      let selectedDevice = RaMedia.getSelectedVideoInputDevice();
      if (!selectedDevice) {
        RaMedia.selectDefaultDevices();
      }


      let selectedArDevice = null;
      if (this.props.excludeArSelectedDevice === true && RaMedia.isArVideoInputDeviceEnable()) {
        selectedArDevice = RaMedia.getSelectedArVideoInputDevice();
      }

      this.props.videoInputDevices.forEach(item => {
        let index = videoInputDevices.length + 1;

        if (!this.props.excludeArSelectedDevice
                || !selectedArDevice || selectedArDevice.deviceId !== item.deviceId) {
            videoInputDevices.push(<option key={"video_" + index} 
                                    value={item.deviceId}>
                                      {item.label ? item.label : ('Camera'+(this.props.videoInputDevices.length > 1? ' #'+index:''))}
                                  </option>);
          }
      });
    }
    else {
      videoInputDevices = <option key="video_null" value="" >{localeStrings.inputVideoNoOptions}</option>;
    } 

    return (videoInputDevices);
  }

  componentDidMount = () => {
    
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    
    if (this.props.videoInputDevices
      && (!prevProps || !prevProps.videoInputDevices || JSON.stringify(this.props.videoInputDevices) !== JSON.stringify(prevProps.videoInputDevices))) {
        let selectedDevice = RaMedia.getSelectedVideoInputDevice();
        if (this.props.containerSelector && selectedDevice) {
          this.$$(this.props.containerSelector).val(selectedDevice.deviceId);
        }
      }
  }
};

// #region Redux
const mapStateToProps = state => {
  return {
    videoInputDevices: state.remoteAssistance.videoInputDevices,
    selectedVideoInputDevice: state.remoteAssistance.selectedVideoInputDevice,
  };
};

const mapDispatchToProps = dispatch => {
  return {

  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(InputVideoOptions);