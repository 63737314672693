
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    exitAppTitle: 'Exit',
    exitAppText: 'Do you want to exit the app?',
    checkingBrowserLabel: 'Checking your browser...',
    checkingBrowserLabelSubtitle: 'This process is automatic, you wil be redirected to the app shortly',
    checkingBrowserError: 'This browser is not supported',
    checkingBrowserErrorSubtitle: 'Please use Chrome browser to run this application',
  },
  it: {
    exitAppTitle: 'Esci',
    exitAppText: 'Vuoi uscire dall\'app?',
    checkingBrowserLabel: 'Verifico il tuo browser...',
    checkingBrowserLabelSubtitle: 'Questa procedura è automatica, verrai reindirizzato all\'applicazione tra poco',
    checkingBrowserError: 'Questo browser non è supportato',
    checkingBrowserErrorSubtitle: 'Utilizza il browser Chrome per eseguire questa applicazione',
  }
});
  
  export default localeStrings;