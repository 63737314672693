import React from 'react';
import { Block, Col, Preloader, Fab, Icon, Button } from 'framework7-react';
import { connect } from 'react-redux';
import LocalVideoStream from '../../components/videostream/local-video-stream';
//import RemoteVideoStream from '../../components/videostream/remote-video-stream.jsx_obsolete';
import Spinner from '../../components/commons/Spinner';
import SnapshotButton from './buttons/snapshot-button'
import { RaApiCallsPics, RaStorage, RaUIAnnotationCanvas, RaApiCalls, RaCordova, RaLog } from 'pikkart-remote-assistance';
import Commons from '../../Commons';
import localeStrings from './call-home-local';
import ArLoader from './components/ar-loader'

class CallHome extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      errormessage: null,
      spinner: false
    };

    this.showingImage = false;
  }

  render() {

    let errorGui = null;
    if (this.state.errormessage !== null) {
      errorGui = (
        <Block style={{ marginTop: 0, paddingTop: '150px' }}>
          <p style={{ textAlign: 'center', color: 'white' }}>
            {this.state.errormessage}
          </p>
        </Block>
      );
    }


    if (this.props.takenSnapshots === null) {
      return (<Spinner />);
    }

    let annotationGUI;
    if (this.props.selectedSnapshot
      && this.props.callGUIMode === RaApiCalls.GUIMODE_ANNOTATIONS
      && this.props.activeCall != null) {
      annotationGUI = this.getSelectedImage();
    }

    let streamVideoUI = null;
    //if (!RaApiCalls.getActiveCall() || Commons.shareUserVideoTrack()) {
      streamVideoUI = (<LocalVideoStream online={true} showSpinnerHandler={() => { return !this.props.callingState }}></LocalVideoStream>);
   /* }
    else if (RaApiCalls.getActiveCall() && !Commons.shareUserVideoTrack()) {
      streamVideoUI = (<RemoteVideoStream participantId={RaApiCalls.getActiveCall().CallerUniqueId}>
        -      </RemoteVideoStream>);
    }*/

    let fab;
    if (RaApiCalls.getActiveCall() && RaApiCalls.amICallController() && this.props.callingState === false) {
        
        fab = (<SnapshotButton onClick={() => {return new Promise((resolve, reject) => {this.takeSnapshot().then(resolve);})}}></SnapshotButton>);
        
    }


    return (
      <div className={this.$f7.device.android && !RaCordova.isCordova() ? 'callTabAndroidChrome' : 'callTab'}>
        <div style={{ display: this.props.callGUIMode === RaApiCalls.GUIMODE_LIVECAMERA ? 'block' : 'none', overflow: 'hidden' }}>

          {errorGui}
          {fab}
          {streamVideoUI}


        </div>
        {annotationGUI}
      </div>
    );
  }

  setRenderingErrorHandler = (message, error) => {
    this.setState({ errormessage: message });
  }

  getSelectedImage = () => {
    if (this.props.selectedSnapshot) {
      if (this.state.spinner === true) {
        return (
          <Block className="row demo-preloaders align-items-stretch text-align-center">
            <Col>
              <Preloader size={42}></Preloader>
            </Col>
          </Block>
        );
      }
      else {

        let closeButton;
        //if (this.isUserCaller())
        if (RaApiCalls.amICallController())
          closeButton = (<Button color="gray" onClick={() => {
            RaApiCallsPics.selectPic(null, null);
            RaApiCalls.switchToLiveCameraGUI();
          }}>{localeStrings.close_selected_pic}</Button>);

        if (this.props.selectedSnapshot.UniqueId === this.state.selectedImageUniqueId) {
          return (<div>
            <RaUIAnnotationCanvas
              live={true}
              cancelAnnotationButton={

                <Fab position="right-top" slot="fixed" color='gray' style={{ marginTop: 0 }}>
                  <Icon ios="f7:arrow_uturn_left" aurora="f7:arrow_uturn_left" md="material:undo"></Icon>
                </Fab>}

              backgroundImage={this.props.selectedSnapshot}
              backgroundImageSrc={this.props.selectedSnapshotImgSrc}
              imageUniqueId={this.props.selectedSnapshot.UniqueId}
              callId={this.props.activeCall.Call.UniqueId}
              dataTrack={this.props.dataTrack}
              myProfile={this.props.profile}
              $$={this.$$} >
            </RaUIAnnotationCanvas>
            {closeButton}
          
            <ArLoader></ArLoader>
          </div>);
        }
      }
    }

    return null;
  }

  showImage = (img) => {


    const self = this;

    self.setState({ spinner: false, selectedImageUniqueId: img.UniqueId }, () => {
   
      if (self.showingImage === false) {
        self.showingImage = true;
        
        let imgSrc = null;
        if (img !== null) {
          
          let htmlImage = document.getElementById('img_'+img.UniqueId);
          if (htmlImage) {
            imgSrc = htmlImage.src;
          }
        }

        RaApiCallsPics.selectPic(img, imgSrc);

        self.showingImage = false;
      }

      
    });
  }

  isUserCaller = () => {
    return RaApiCalls.getActiveCall() && RaApiCalls.getActiveCall().CallerUniqueId === RaStorage.getProfile().UniqueId;
  }

  componentDidMount = () => {

    RaApiCalls.connectToCall(Commons.shareUserVideoTrack(), true)
      .catch(error => {
        if (this.props.connectToCallError) {
          this.props.connectToCallError(error);
        }
      });

    if (this.props.live && this.props.takenSnapshots && this.props.takenSnapshots.length > 0) {
      if (this.props.onPicSelectedHandler)
        this.props.onPicSelectedHandler(this.props.selectedSnapshot);
    }

    if (this.props.live) {
      RaApiCallsPics.sendSelectedPicRequestMessage();
    }
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {


    if (prevProps === null || this.props.takenSnapshots !== prevProps.takenSnapshots)
      this.setState({ errormessage: null });
    

    if (prevProps.selectedSnapshot !== this.props.selectedSnapshot && this.props.selectedSnapshot) {
      this.showImage(this.props.selectedSnapshot);
    }
  }

  deletePic = (picId) => {

    const errorFn = (errorMsg) => {
      if (errorMsg !== this.state.errormessage) {
        this.setState({
          spinner: false,
          errormessage: errorMsg,
        });
      }
    }

    const successFn = () => {

      this.setState({ spinner: false, errormessage: null });

    }

    this.$f7.dialog.confirm('Do you want to delete this pic?', 'Pics', () => {
      this.setState({ spinner: true });
      RaApiCallsPics.deletePic(picId)
        .then(function (response) {
          if (response.result.success === true) {
            successFn();
          } else {
            errorFn(response.result.message);
          }
        })
        .catch(function (response) {
          //handle error
          errorFn(response.message);
        });
    })
  }

  takeSnapshot() {
    let self = this;
    return new Promise((resolve, reject) => {

      const error = () => {
        self.$f7.dialog.alert('video unavailable', 'Video');
        resolve();
      }


      let tracksContainer = document.getElementsByClassName('tracksContainer');
      let videoDiv = null;

      if (tracksContainer) {
        tracksContainer = tracksContainer[0];
        let assistedDiv = tracksContainer.getElementsByClassName('mainview-participants');
        if (assistedDiv && assistedDiv.length > 0) {

          var divs = assistedDiv[0].getElementsByTagName('video');
          var videoTagArray = [];
          for (var i = 0; i < divs.length; i += 1) {
            videoTagArray.push(divs[i]);
          }
    
          if (videoTagArray.length > 0)
            videoDiv = videoTagArray[0];
    
          if (videoDiv) {
            const canvasDiv = self.createCanvas(tracksContainer);
    
            if (tracksContainer != null) {
              self.setCanvasSizeToVideo(canvasDiv, videoDiv);
              setTimeout(() => {
                self.takeLocalVideoSnapshot(self, videoDiv, canvasDiv).then(resolve);
              }, 200);
            }
            else {
              error();
            }
          }
          else {
            error();
          }
        }
        else {
          error();
        }
      }
      else {
        error();
      }
    });
  }

  createCanvas = (div) => {
    var canvas = document.createElement('canvas');
    canvas.id = "videoCanvas";
    //div.appendChild(canvas);

    return canvas;
  }

  takeLocalVideoSnapshot = (self, video, canvas) => {
    return new Promise((resolve, reject) => {


      let height = video.videoHeight;
      let width = video.videoWidth;

      canvas.width = width;
      canvas.height = height;
      var context = canvas.getContext('2d');
      context.clearRect(0, 0, width, height);
      context.drawImage(video, 0, 0, width, height);

      const jpegUrl = canvas.toDataURL("image/jpeg");

      const base64Url = jpegUrl.substring(jpegUrl.lastIndexOf(',') + 1)

      const errorFn = (errorMsg) => {

        self.showToastBottom(errorMsg);
        resolve();
      }

      const successFn = (resultData) => {
        self.setState({ errormessage: null });
        resolve();
      }

      //const bodyFormData = new FormData();
      //bodyFormData.set('picbase64', base64Url);
      RaApiCallsPics.saveNewPic(base64Url)
        .then(function (response) {
          //handle success
          RaLog.log(response);

          if (response.result.success === true) {
            // handle success
            //response.data.data contiene il token
            successFn(response);
          } else {
            // handle error
            errorFn(response.result.message);
          }
        })
        .catch(function (response) {
          //handle error
          errorFn(response.message);
        });
    });
  }

  setCanvasSizeToVideo = (canvas, video) => {
    if (video) {

      let height = video.getAttribute('track-height');
      let width = video.getAttribute('track-width');
  
      canvas.height = height ? parseInt(height) : video.clientHeight;
      canvas.width = width ? parseInt(height) : video.clientWidth;
    }
    else {
      canvas.height = 0;
      canvas.width = 0;
    }
  }

  showToastBottom = (text) => {
    this.toastBottom = this.$f7.toast.create({
      text: text,
      closeTimeout: 2000,
    });
    this.toastBottom.open();
  };

};

// #region Redux
const mapStateToProps = state => {
  return {
    takenSnapshots: state.remoteAssistance.takenSnapshots,
    selectedSnapshot: state.remoteAssistance.selectedSnapshot,
    selectedSnapshotImgSrc: state.remoteAssistance.selectedSnapshotImgSrc,
    dataTrack: state.remoteAssistance.dataTrack,
    profile: state.remoteAssistance.profile,
    callGUIMode: state.remoteAssistance.callGUIMode,
    activeCall: state.remoteAssistance.activeCall,
    callingState: state.remoteAssistance.callingState
  };
};

const mapDispatchToProps = dispatch => {
  return {

  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(CallHome);
