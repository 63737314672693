
import { combineReducers } from 'redux'
import appReducer from './appReducer';
import {RaReduxReducer} from 'pikkart-remote-assistance';

export default () => {
    
    return combineReducers({
        app : appReducer,
        remoteAssistance : raReducer
    });

    function raReducer (state, action) {
        RaReduxReducer.setReducerName('remoteAssistance');
        return RaReduxReducer.getReducer(state, action);
    }
} 


