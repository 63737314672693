import React from 'react';
import { connect } from 'react-redux';
import { ListItem, Toggle} from 'framework7-react';
import {Ra, RaMedia} from 'pikkart-remote-assistance';
import localeStrings from './inputarvideo-toggle-local';

class InputArVideoToggle extends ListItem {


  constructor(props) {
    super(props);

    this.state = {
      
    };
  }


  render() {

    let videoArToggleChecked = RaMedia.isArVideoInputDeviceEnable();
    let videoArToggleEnabled = this.props.arVideoInputDevices && this.props.arVideoInputDevices.length > 0;
    const videoArToggle = Ra.getConfig().arEnabled ? <ListItem>
                                                        <div slot='before-title' style={{fontSize: '12px', color: 'rgba(0, 0, 0, 0.65)'}}>{localeStrings.inputArVideoToggleLabel}</div>
                                                        <Toggle 
                                                            slot='after-title'
                                                            defaultChecked={videoArToggleChecked}
                                                            disabled={!videoArToggleEnabled || this.props.callContext}
                                                            onToggleChange={(checked) => {
                                                              RaMedia.setArVideoInputDeviceEnable(checked);
                                                            }} />
                                                      </ListItem> : null;
 
    return (videoArToggle);
  }

  componentDidMount = () => {
    
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    
  }
};

// #region Redux
const mapStateToProps = state => {
  return {
    arVideoInputDevices: state.remoteAssistance.arVideoInputDevices,
    isArVideoInputDeviceEnable: state.remoteAssistance.isArVideoInputDeviceEnable,
  };
};

const mapDispatchToProps = dispatch => {
  return {

  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(InputArVideoToggle);