import React from 'react';
import { BlockTitle, Progressbar } from 'framework7-react';
import { connect } from 'react-redux';
import { RaMedia} from 'pikkart-remote-assistance';
import localeStrings from './ar-loader-local';

class ArLoader extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {

        let fab = null;
        if (RaMedia.isArVideoInputDeviceEnable() 
                    && this.props.arVideoTrainingProgress < 100) {
                fab = (<div style={{position: 'fixed', textAlign: "center", width: '50%', left: '27%', bottom: '100px'}}>
                        <div style={{ width: '90%'}}>
                          <BlockTitle style={{color:'white'}}>{localeStrings.loading_ar_text}</BlockTitle>
                          <p><Progressbar color='white' progress={this.props.arVideoTrainingProgress} id="demo-inline-progressbar"></Progressbar></p>
                        </div>
                      </div>);
        }

        return fab;
    }

    componentDidMount = () => {

    }

    componentWillUnmount = () => {
        
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
    }

};

// #region Redux
const mapStateToProps = state => {
    return {
        arVideoTrainingProgress: state.remoteAssistance.arVideoTrainingProgress
    };
};

const mapDispatchToProps = dispatch => {
    return {
        
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(ArLoader);
//export default connect(mapStateToProps)(CurrentCalls);