
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    popup_title: "Do you need assistance?",
    popup_text: 'Start a new call to try our remote assistance service.',
    popup_button: 'Got it!',
    nominutes_alert_title: "No minutes left",
    nominutes_alert_text: 'Sorry, you have no more minutes left, you need to buy more assistance minutes.',
    notificationservice_alert_title: "Notification service error",
    newcall_button: 'New call',
  },
  it: {
    popup_title: "Hai bisongo di assistenza?",
    popup_text: 'Avvia una chiamata e prova il nostro servizio di assistenza',
    popup_button: 'Ricevuto!',
    nominutes_alert_title: "Minuti residui",
    nominutes_alert_text: 'Spiacente, non hai minuti residui, acquista nuovi minuti di assistenza.',
    notificationservice_alert_title: "Errore del servizio di notifiche",
    newcall_button: 'Chiama',
  }
});
  
  export default localeStrings;