import React from 'react';
import { Block, Button } from 'framework7-react';
import { connect } from 'react-redux';
import { RaApiContacts, RaStorage, RaCordovaNet, RaApiCalls, RaUITracksNodesManager, RaCordova, RaCordovaPermissions, RaApiCallsCommunicationProvider, RaMedia, RaLog } from 'pikkart-remote-assistance';
import IpCamStream from '../ipcam/ipcam-stream';
import Spinner from '../commons/Spinner';
import TrackRender from '../commons/track-render';
import Commons from '../../Commons';
import localeStrings from './local-video-stream-local';

const RENDERDEVICE_IPCAM = "RENDERDEVICE_IPCAM";
const RENDERDEVICE_LOCALDEVICE = "RENDERDEVICE_LOCALDEVICE";


const RENDERERROR_IPCAM_ROUTER = "IPCAM_ROUTER";
const RENDERERROR_IPCAM_NOTCONNECTED = "IPCAM_NOTCONNECTED";

class LocalVideoStream extends React.Component {
  
  constructor(props) { 
    super(props);
    this.state = {
        trackSizeDetected: false,
        renderingError: null,
        renderingErrorCode: null
    };
    this.ipCamStreamDimensions = null;
    this.ipCamStreamPublished = false;

    this.renderingErrorCodes = [RENDERERROR_IPCAM_ROUTER, RENDERERROR_IPCAM_NOTCONNECTED];

  }

  getLocalParticipantTracksNode = () => {
    return document.getElementById(RaStorage.getProfile().UniqueId);
  }


  render() { 

    let spinner = null;
    if (this.state.trackSizeDetected !== true && this.state.renderingError === null
        && (!this.props.showSpinnerHandler || this.props.showSpinnerHandler()))
        spinner = (<Spinner></Spinner>);
        

    let ipCamStream = null;
    if (this.props.selectVideoInputIpCam)
    ipCamStream = (<IpCamStream 
                    imgId='ipcam-image' 
                    canvasId='ipcam-canvas'
                    setRenderingError={this.setIpCamRenderingErrorHandler} 
                    setIpCamStreamDimensions={this.setIpCamStreamDimensionsHandler}
                    ipCamConnecting={this.ipCamConnectingHandler}
                    ipCamConnected={this.ipCamConnectedHandler}>

                    </IpCamStream>);


    let errorMessage = null;
    let settingsButton = null;
    if (this.state.renderingError !== null) 
    {
        if (this.state.renderingErrorCode !== null) {
            settingsButton = (<div style={{marginTop: '30px', textAlign: 'center'}}><Button fill color='gray' style={{display: 'inline-block'}} onClick={(e)=>{
                if (RaCordova.isCordova()) {
                    RaCordovaNet.openWiFiSettings(function() { }, function(error) {console.error(error);});
                }
            }}>SETTINGS...</Button></div>);
        } 

        errorMessage = (
            <Block style={{marginTop: 0, paddingTop:'150px'}}>
                <p style={{textAlign: 'center', color:'white'}}>
                    {this.state.renderingError}
                </p>
                {settingsButton}
            </Block>
        );           
    }

    let offLineVideoTrack = null;
    if (!RaApiCalls.getActiveCall()) {
        offLineVideoTrack = (<div className='offline-videotrack'></div>);
    }

    return (
        <div id="uiTracksContainer">
            {spinner}
            {errorMessage}
            <div style={{ textAlign: 'center', display: (this.state.trackSizeDetected === true && this.state.renderingError == null? 'block':'none') }}>
                <TrackRender></TrackRender>
                {ipCamStream}
                {offLineVideoTrack}
            </div>
        </div>); 
  }

  getRenderingErrorCode = (error) => {
    let code = null;
    this.renderingErrorCodes.forEach(renderingCode => {
        if (error === renderingCode) {
            code = renderingCode;
        }
    });
    return code;
  }

  ipCamConnectingHandler = () => {
      this.setState({trackSizeDetected: false, renderingError: null});
  }

  ipCamConnectedHandler = () => {
      this.setState({trackSizeDetected: true});
  } 

  setIpCamRenderingErrorHandler = (message, error) => {
    if (this.props.selectVideoInputIpCam === null)
        return;
    
    this.setRenderingErrorHandler(message, error);
  }

  setRenderingErrorHandler = (message, error) => {
      const code = (error) ? this.getRenderingErrorCode(error) : null;
      if (code === RENDERERROR_IPCAM_NOTCONNECTED) {
        Commons.showErrorMessage(this, localeStrings.ipCam_notConnected_message);
      }
      else {
          if ((message && !this.state.renderingError) || (!message && this.state.renderingError)) {
            if (message) {
                RaLog.error(message, error);
            }
            this.setState({renderingError: message ? localeStrings.ipCam_renderingError_message:null, renderingErrorCode: code});
          }
      }
  }

  setIpCamStreamDimensionsHandler = (dimensions) => {
    this.ipCamStreamDimensions = dimensions;
    RaLog.log('ip cam stream dimensions:', dimensions);
    if (!this.ipCamStreamPublished) {
        this.setupVideoIpCam(true);
        this.ipCamStreamPublished = true;
    }
  }

  getVideoTrackSizeCallback = (videoTrack) => {
    let streamDimensions = videoTrack.dimensions;
    if (this.props.selectVideoInputIpCam)
        streamDimensions = this.ipCamStreamDimensions;
    else if (!streamDimensions) {
        let currentVideoTrack = RaApiCallsCommunicationProvider.getCurrentLocalVideoTrack();
        if (currentVideoTrack && currentVideoTrack.track)
        streamDimensions = currentVideoTrack.track.dimensions;
    }

    return streamDimensions;
  }

  getVideoCropSizeCallback = () => {
    return {width: this.$$(window).width(), height: this.$$(window).height()};
  }

  isTrackSourceAvailableCallback = (trackSourceId) => {
    if ((trackSourceId === RENDERDEVICE_LOCALDEVICE && this.props.selectVideoInputIpCam !== null)
        || (trackSourceId === RENDERDEVICE_IPCAM && this.props.selectVideoInputIpCam === null)
        || (RaApiCalls.getActiveCall() && !this.props.online)
        || (!RaApiCalls.getActiveCall() && this.props.online))
        return false;

    return true;
  }

  cropLocalVideoPlaceHolder = (trackSourceId, videoTrack, renderElementSelector, 
                                    isTrackSourceAvailableCallback, getVideoCropSizeCallback, getVideoTrackSizeCallback) => {

  //  RaLog.log('cropLocalVideoPlaceHolder');

    if (!isTrackSourceAvailableCallback) {
        console.error('isTrackSourceAvailableCallback not defined');
        sessionStorage.removeItem('aspectRatio');
        return;
    }

    if (!getVideoCropSizeCallback) {
        console.error('getVideoCropSizeCallback not defined');
        return;
    }

    if (!getVideoTrackSizeCallback) {
        console.error('getVideoTrackSizeCallback not defined');
        return;
    }

    
    //se è stato cambiato il dispositivo di render prima della chamata a questo metodo non lo eseguo
      if (!isTrackSourceAvailableCallback(trackSourceId)) {
        if (RaApiCalls.getActiveCall() && !this.props.online) {
            
          if (!videoTrack.track) {
            videoTrack.stop();
          }
        }
        return;
      }

      let self = this;
      setTimeout(function () {

          let cropSize = Commons.getVideoCropSizeCallback(self);

          let trackWidth = null;
          let trackHeight = null;

          let streamDimensions = getVideoTrackSizeCallback(videoTrack);

          if (streamDimensions)
          {
            trackWidth = streamDimensions.width;
            trackHeight = streamDimensions.height;
          }

      //    RaLog.log('cropLocalVideoPlaceHolder trackWidth:' + trackWidth + ' trackHeight:' + trackHeight);

          if (trackWidth && trackHeight) {
              if (self.state.showVideoLowStreamingMessage === true)
                  self.setState({ showVideoLowStreamingMessage: false });

              let isAssisted = RaApiContacts.isAssistedContact(RaStorage.getProfile());

              let videoCropData = Commons.getVideoCropData(isAssisted, true, cropSize, trackWidth, trackHeight);//RaUI.getElementCropData(cropSize.width, cropSize.height, trackWidth, trackHeight);

              if (sessionStorage.getItem('aspectRatio') !== videoCropData.aspectRatio) {
                let videoEl = self.$$(renderElementSelector);
                  if (trackWidth >= trackHeight) {
                    if (!videoEl.hasClass("landscape")) {
                        videoEl.addClass("landscape")
                    }
                  }
                  else {
                    if (!videoEl.hasClass("landscape")) {
                        videoEl.removeClass("landscape")
                    }
                  }
           //     Commons.setVideoTrackNodeStyle(self, renderElementSelector, videoCropData, isAssisted, true);

                //                  self.$$(renderElementSelector).attr("style", "width:" + videoCropData.width + "px;height:" + videoCropData.height + "px;margin-top:" + videoCropData.margin.top+"px;margin-left:"+videoCropData.margin.left+"px;overflow: hidden");
                  sessionStorage.setItem('aspectRatio', videoCropData.aspectRatio);
                  if (self.state.trackSizeDetected !== true)
                  {
                    self.setState({trackSizeDetected: true});
                    if (self.props.onTrackSizeDetected)
                      self.props.onTrackSizeDetected();
                  }    
              }
          }
          else {
              if (self.state.showVideoLowStreamingMessage !== true)
                  self.setState({ showVideoLowStreamingMessage: true });
          }
          
          self.cropLocalVideoPlaceHolder(trackSourceId, videoTrack, renderElementSelector, isTrackSourceAvailableCallback, getVideoCropSizeCallback, getVideoTrackSizeCallback);

      }, 2000);
  }

  checkCameraPermissions = () => {
        
    if (RaCordova.isCordova()) {
        const self = this;
        RaCordovaPermissions.askCallPermissions()
        .then(function (response) {
            RaLog.log('request permissions success ' + JSON.stringify(response));
        })
        .catch(function (response) {
            RaLog.log('request permissions failed ' + JSON.stringify(response));

            self.$f7.dialog.alert('You need to grant permissions in order to start the call', "Permessions", function () {
                self.checkCameraPermissions();
            });
        });
    }
  }

  componentDidMount = () => {
    this.checkCameraPermissions();
    this._localParticipantTracksNode = this.getLocalParticipantTracksNode();
    this.setupInputDevices({});
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this._localParticipantTracksNode = this.getLocalParticipantTracksNode();
    this.setupInputDevices(prevProps);
  }

  setupInputDevices = (prevProps) => {

    let self = this;
    //GESTIONE TRACCIA VIDEO
    if (!self.props.selectVideoInputIpCam)
    {
        if (!self.props.inputDevicesDetectionCompleated) {
            RaMedia.selectDefaultDevices();
        }
        else {
            if (!self.props.selectedVideoInputDevice) {
                RaMedia.selectDefaultDevices();
            }
            else {
                let changeVideoInputDevice = prevProps.selectVideoInputIpCam || !prevProps.selectedVideoInputDevice || prevProps.selectedVideoInputDevice.deviceId !== self.props.selectedVideoInputDevice.deviceId;
                //rilevati i device
                if (!prevProps.inputDevicesDetectionCompleated 
                //avviata/finite una chiamata
                || ((!prevProps.comunicationProviderRoom && self.props.comunicationProviderRoom) || (prevProps.comunicationProviderRoom && !self.props.comunicationProviderRoom)) 
                //selezionato/modificato il  device locale
                || changeVideoInputDevice)    
                {
                    self.setupVideoInputDevice(true, changeVideoInputDevice);
                }
            }
        }
    }
/*
    else if(self.props.selectVideoInputIpCam && !prevProps.selectVideoInputIpCam) 
        self.setupVideoIpCam(true);*/  //NON CAMBIO LA TRACCIA UN QUESTO PUNTO, PERCHE' NON SI SANNO ANCORA LE DIMENSIONI DELLO STREAM DELL'IP, CHIAMO QUINDI QUESTO METODO NELL'HANDLER DELLE DIMENSIONI
  }

  setupVideoIpCam = (shareVideoTrack) => {
      
    let self = this;
    self.setState({trackSizeDetected: false});
    let canvas = document.getElementById("ipcam-canvas");
    let mediaStream = canvas.captureStream();
    let tracks = mediaStream.getTracks().map(track => track.kind === 'audio' ? RaApiCallsCommunicationProvider.getLocalAudioTrack(track) : RaApiCallsCommunicationProvider.getLocalVideoTrack(track));

    RaLog.log('ip cam track detected', tracks);
    if (shareVideoTrack && RaApiCallsCommunicationProvider.isVideoCallActive()) {
        RaApiCallsCommunicationProvider.getCurrentLocalVideoTrack();
        RaApiCallsCommunicationProvider.publishNewTracks(tracks)
        .then(function() {
            self.cropLocalVideoPlaceHolder(RENDERDEVICE_IPCAM, self.getVideoTrack(tracks), '#ipcam-canvas', self.isTrackSourceAvailableCallback, self.getVideoCropSizeCallback, self.getVideoTrackSizeCallback);
        }).catch( function (errorMessage) {
            self.setRenderingErrorHandler(errorMessage);
        });
    }
    else {
        self.cropLocalVideoPlaceHolder(RENDERDEVICE_IPCAM, self.getVideoTrack(tracks), '#ipcam-canvas', self.isTrackSourceAvailableCallback, self.getVideoCropSizeCallback, self.getVideoTrackSizeCallback);
    }
  }

  setupVideoInputDevice = (shareVideoTrack, changeVideoInputDevice) => {
    this.setRenderingErrorHandler(null);
    this.ipCamStreamPublished = false;
    let self = this;
    self.setState({trackSizeDetected: false});
    let currentVideoTrack = RaApiCallsCommunicationProvider.getCurrentLocalVideoTrack();
    //chiamata in corso e condivisione del mio streaming video
    if (currentVideoTrack) {
        self.cropVideoTrackStream(self, currentVideoTrack);
    }
    else if (!this.props.online) {
        //RaApiCallsCommunicationProvider.getTrackFromLocalVideoInputDevice(RaMedia.getSelectedVideoInputDevice())
        RaMedia.getVideoInputDeviceTrack()
        .then(videoTrack => {
            self.$$('.offline-videotrack').html('');
            self.$$('.offline-videotrack').append(videoTrack.attach());
        //       self.cropLocalVideoPlaceHolder(RENDERDEVICE_LOCALDEVICE, videoTrack, ".offline-videotrack > video", self.isTrackSourceAvailableCallback, self.getVideoCropSizeCallback, self.getVideoTrackSizeCallback);                
        })
        .catch(errorMessage => {
            console.error(errorMessage);
        });
    }
  }

    getVideoTrack = (tracks) => {
        
        let videoTrack = null;
        tracks.forEach(function (track) {
            
            if (track.kind === 'video') {
                videoTrack = track;
            }
        });
        return videoTrack;
    }

    cropVideoTrackStream = (self, track) => { 
        
        self.cropLocalVideoPlaceHolder(RENDERDEVICE_LOCALDEVICE, track, "#"+RaUITracksNodesManager.getTrackNodeId(RaStorage.getProfile().UniqueId) + ' > video', self.isTrackSourceAvailableCallback, self.getVideoCropSizeCallback, self.getVideoTrackSizeCallback);                
    }
  
}

// #region Redux
const mapStateToProps = state => {
    return {
        selectedVideoInputDevice: state.remoteAssistance.selectedVideoInputDevice,
        inputDevicesDetectionCompleated: state.remoteAssistance.inputDevicesDetectionCompleated,
        selectVideoInputIpCam: state.remoteAssistance.selectVideoInputIpCam,
        comunicationProviderRoom: state.remoteAssistance.comunicationProviderRoom
    };
};

const mapDispatchToProps = dispatch => {
    return {
      
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LocalVideoStream);