
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    unmuteRoomText: "Unmute room",
    muteRoomText: 'Mute room',
    deleteRoomText: "Delete this chat",
  },
  it: {
    unmuteRoomText: "Attiva le notifiche",
    muteRoomText: 'Disattiva le notifiche',
    deleteRoomText: "Elimina questa chat",
  }
});
  
  export default localeStrings;