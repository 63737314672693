
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    notification_title: 'Notification permissions missing',
    notification_error: 'In order to receive messages and calls notifications, please enabled notification for this app',
    notification_retry: 'Click to this message to re-check app permissions',
    notification_now: 'now',
  },
  it: {
    notification_title: 'Attiva la ricezione delle notifiche',
    notification_error: 'Per ricevere messaggi e telefonate devi autorizzare l\'app a ricevere le notifiche',
    notification_retry: 'Clicca su questo messaggio per ripetere la verifica',
    notification_now: 'ora',
  }
});
  
  export default localeStrings;