
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    connecting: "connecting...",
    pic_delete_alert_text: 'Do you want to delete this pic?',
    pic_delete_alert_title: 'Pics',
    button_addparticipant: 'add participant',
    button_participants: 'participants',
    button_close_call: 'close call',
    button_mute: 'mute',
    button_unmute: 'unmute',
    popup_messages_title: 'Messages',
    popup_participants_title: 'Participants',
    popup_settings_title: 'Settings',
    ar_switch_error: 'Unable to activate augmented reality',
  },
  it: {
    connecting: "connessione in corso...",
    pic_delete_alert_text: 'Vuoi eliminare questa immagine?',
    pic_delete_alert_title: 'Immagine',
    button_addparticipant: 'aggiungi partecipante',
    button_participants: 'partecipanti',
    button_close_call: 'termina chiamata',
    button_mute: 'microfono in mute',
    button_unmute: 'disattiva mute',
    popup_messages_title: 'Messaggi',
    popup_participants_title: 'Partecipanti',
    popup_settings_title: 'Impostazioni',
    ar_switch_error: 'Non riesco ad attivare la realtà aumentata',
  }
});
  
  export default localeStrings;