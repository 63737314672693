
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    formTitle: 'Setup your devices'
  },
  it: {
    formTitle: 'Configura i tuoi dispositivi'
  }
});
  
  export default localeStrings;