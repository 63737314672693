
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    loading_text: "loading messages...",
  },
  it: {
    loading_text: "sto caricando i messaggi...",
  }
});
  
  export default localeStrings;