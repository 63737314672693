
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    inputVideoNoOptions: 'No input video found',
  },
  it: {
    inputVideoNoOptions: 'Nessuna telecamera rilevata',
  }
});
  
  export default localeStrings;