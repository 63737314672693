import { connect } from 'react-redux';
import React from 'react';
import { Progressbar, BlockTitle } from 'framework7-react';
import localeStrings from './recents-loader-local';


export class RecentsLoader extends React.Component {

  
  render() {

    let loader = null;
    if (this.props.loadingRecents.loading === true) {
      loader = (<div style={{textAlign: "center", width: '100%', paddingLeft: '10%'}}>
                  <div style={{ width: '80%'}}>
                    <BlockTitle>{localeStrings.loading_text}</BlockTitle>
                    <p><Progressbar progress={this.props.loadingRecents.loadingPerc} id="demo-inline-progressbar"></Progressbar></p>
                  </div>
                </div>);
    }

    return loader;
  }
}

// #region Redux
const mapStateToProps = state => {
  return {
    loadingRecents: state.app.loadingRecents, 
  };
};

const mapDispatchToProps = dispatch => {
  return {
    
  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(RecentsLoader);