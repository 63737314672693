import { connect } from 'react-redux';
import React from 'react';


export class RedirectEvents extends React.Component {


  
  render() {

    return null;
  }

  componentDidMount = () => {
    
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    

    const self = this;
    if (self.props.incomingCall != null && prevProps.incomingCall === null) {
      
      let popups =  self.$$('.ra-popup');
      popups.forEach(popup => {
        self.$f7.popup.close('#'+popup.id, true);
      })
      self.props.router.navigate('/incomingcall/', { transition: 'f7-cover-v'} );  // non devo mettere reladAll a true altrimenti no vanno le transizioni
    }
  }
}

// #region Redux
const mapStateToProps = state => {
  return {
    loggedIn: state.remoteAssistance.loggedIn,
    incomingCall: state.remoteAssistance.incomingCall,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    
  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(RedirectEvents);