
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    tab_bottom_home: "Home",
    tab_bottom_chat: "Messages",
    tab_bottom_recents: "Recents",
    tab_bottom_documents: "Documents",
    tab_bottom_profile: "Profile",
    tab_bottom_glasses: "Glasses Settings",
    tab_bottom_settings: "Settings",
    tab_bottom_credits: "Credits",
  },
  it: {
    tab_bottom_home: "Home",
    tab_bottom_chat: "Messaggi",
    tab_bottom_recents: "Recenti",
    tab_bottom_documents: "Documenti",
    tab_bottom_profile: "Profilo",
    tab_bottom_glasses: "Imp. Occhiali",
    tab_bottom_credits: "Credits",
  }
});
  
  export default localeStrings;