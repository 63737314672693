
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    inputArVideoLabel: 'Augmented reality camera',
  },
  it: {
    inputArVideoLabel: 'Webcam per Realtà Aumentata',
  }
});
  
  export default localeStrings;