import React from 'react';
import { connect } from 'react-redux';
import {RaMedia} from 'pikkart-remote-assistance';

class InputArVideoPreview extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      
    };

    this.videoTrack = null;
  }


  render() {


    return (<div slot="content" className="settings-arvideotrack"></div>);
  }

  componentDidMount = () => {
    if (!this.props.selectedArVideoInputDevice 
          && this.props.arVideoInputDevices && this.props.arVideoInputDevices.length > 0) {
      RaMedia.selectArVideoInputDevice(this.props.arVideoInputDevices[0]);
    }

    if (this.props.selectedArVideoInputDevice) {
      this.showVideoTrack();
    }
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    
    if (!this.props.selectedArVideoInputDevice 
          && this.props.arVideoInputDevices && this.props.arVideoInputDevices.length > 0) {
      RaMedia.selectArVideoInputDevice(this.props.arVideoInputDevices[0]);
    }

    if (this.props.selectedArVideoInputDevice) {
      let selectedArVideoInputDeviceChanged =  (!prevProps || !prevProps.selectedArVideoInputDevice || prevProps.selectedArVideoInputDevice.deviceId !== this.props.selectedArVideoInputDevice.deviceId);
      
      if (selectedArVideoInputDeviceChanged) {
        this.showVideoTrack();
      }
    }
    else  {
      this.$$('.settings-arvideotrack').html('');
    }
    
  }

  componentWillUnmount = () => {
    
    RaMedia.stopArVideoInputDeviceTrack();
  }

  showVideoTrack = () => {

    //se c'è una chiamata in corso leggo la traccia da lì 
    const self = this;
    RaMedia.getArVideoInputDeviceTrack()
    .then((track) => {
      self.$$('.settings-arvideotrack').html('');
      if (track) {
        self.$$('.settings-arvideotrack').append(track.attach());
      }
    });

  }
};

// #region Redux
const mapStateToProps = state => {
  return {
    selectedArVideoInputDevice: state.remoteAssistance.selectedArVideoInputDevice,
    arVideoInputDevices: state.remoteAssistance.arVideoInputDevices,
    activeCall: state.remoteAssistance.activeCall
  };
};

const mapDispatchToProps = dispatch => {
  return {

  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(InputArVideoPreview);