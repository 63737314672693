import React from 'react';
import { Block, List, ListItem, Col, Preloader, Popup, Page, Navbar, Link, NavLeft, NavRight, PageContent, Searchbar } from 'framework7-react';
import { connect } from 'react-redux';
import {RaApiRooms, RaApiAuth, RaUI} from 'pikkart-remote-assistance';
import Commons from '../../Commons';
import localeStrings from './rooms-local';
import ChatMessages from '../../components/commons/chat-messages';
import RoomsMuteButton from './rooms-mute-button';
import ContactsList from '../../components/commons/contacts-list';
import IncomingCallPopup from '../incoming-call/incomingcall-popup';
import MenuToolbar from '../../components/menu/toolbar';
import LogoutLoader from '../../components/logout/logout-loader';

class Rooms extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      errormessage: null,
      updateIntervalId: null,
      clickedRoom: null,
      roomMute: false,
    };
  }


  render() {

    if (!RaApiAuth.isAuthenticated()) {
      return (<LogoutLoader router={this.$f7router}></LogoutLoader>);
    }

    let pageContent = null;
    if (this.state.errormessage !== null) {
      console.error(this.state.errormessage);
      pageContent =  (
          <Block className="row demo-preloaders align-items-stretch text-align-center">
            <Col>
              <h3>{localeStrings.list_error}</h3>
            </Col>
          </Block>
      );
    }
    else if (this.props.contactRooms === null) {
      pageContent = (
          <Block className="row demo-preloaders align-items-stretch text-align-center">
            <Col>
              <Preloader size={42}></Preloader>
            </Col>
          </Block>
      );
    }
    else {
      pageContent = this.getRooms(this.props.contactRooms);
    }

    const portrait = Commons.isPortrait(this);

    let navLeft = null;
    if (portrait) {
      navLeft = (<NavLeft>
          <Link panelOpen="left" iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu"></Link>
        </NavLeft>);
    }

    return (
      <Page pageContent={false} colorTheme="custom">
        <Navbar large largeTransparent sliding={false}>
          {navLeft}
          <NavRight>
            <Link searchbarEnable=".searchbar-demo" iconIos="f7:search" iconAurora="f7:search" iconMd="material:search"></Link>
            <Link iconIos="f7:plus" iconAurora="f7:plus" iconMd="material:person_add"
              onClick={(contact) => { this.showContactList(); }}></Link>
          </NavRight>
          <Searchbar
            className="searchbar-demo"
            expandable
            searchContainer=".search-list"
            searchIn=".item-title"
            disableButton={!this.$theme.aurora}
            placeholder={localeStrings.search_placeholder}
          ></Searchbar>
        </Navbar>
        <PageContent style={{paddingTop: (!portrait?'110px':'inherits')}}>
          
          
          {pageContent}
          
          
            
          <Popup className="contacts-popup ra-popup" id="main-contacts-list-popup" colorTheme="custom" opened={this.state.showcontactslist === true && this.props.incomingCall === null} onPopupClose={(e) => { this.closeContactList(); }}>
            <ContactsList id="main-contacts-list" key="main-contacts-list" backPageClicked={(contact) => { this.createRoom(contact); }}
              closeContacts={(e) => { this.closeContactList() }} />
          </Popup>

          <Popup id="chat-popup" className="chat-popup ra-popup" opened={this.state.clickedRoom !== null} onPopupClosed={(e)=> {this.setState({clickedRoom: null});}}>
            <Page>
              <Navbar title={this.state.clickedRoom ? this.state.clickedRoom.Subject:null}>
                <RoomsMuteButton slot="right" room={this.state.clickedRoom}></RoomsMuteButton>
                <Link slot="right" iconMd="material:delete_outline" onClick={(e) => { this.deleteRoomClick(this.state.clickedRoom);}}></Link>
                <Link slot="left" iconMd="material:close" onClick={(e) => { this.setState({clickedRoom: null}); }}></Link>
              </Navbar>     
                <ChatMessages 
                    roomId={this.state.clickedRoom ? this.state.clickedRoom.UniqueId : null}
                    sendMessageEnabled={true} 
                    scrollEnabled={true}
                    messagesMarkedAsReadHandler={this.messagesMarkedAsReadHandler}
                ></ChatMessages>
            </Page>
          </Popup>
          <IncomingCallPopup router={this.$f7router}></IncomingCallPopup>
        </PageContent>
        <MenuToolbar router={this.$f7router}></MenuToolbar>
      </Page>
    );
  }

  deleteRoomClick = (room) => {
    let self = this;
    this.$f7.dialog.confirm(localeStrings.delete_room_alert_title, localeStrings.delete_room_alert_text, (() => {
      self.deleteRoom(self, room); 
    }));
  }

  deleteRoom = (self, room) => {

    const errorFn = (errorMsg) => {
      if (errorMsg !== this.state.errormessage) {
        this.setState({
          errormessage: errorMsg
        });
      }
    }

    RaApiRooms.deleteContactRoom(room.UniqueId)
      .then(function (response) {

        if (response.result.success === true) {

          self.setState({clickedRoom: null, errormessage: null });
        } else {
          errorFn(response.result.message);
        }
      })
      .catch(function (errorMessage) {
        errorFn(errorMessage);
      });
  }
/*
  changeRoomMute = (room) => {

    let self = this;
    const errorFn = (errorMsg) => {
      if (errorMsg !== this.state.errormessage) {
        this.setState({
          errormessage: errorMsg
        });
      }
    }

    let newMuteState = !room.Mute;
    RaApiRooms.setMute(room.UniqueId, newMuteState)
      .then(function (response) {

        if (response.result.success === true) {
          room.Mute = newMuteState;
          self.setState({clickedRoom: room, errormessage: null, roomMute: newMuteState });
        } else {
          errorFn(response.result.message);
        }
      })
      .catch(function (errorMessage) {
        errorFn(errorMessage);
      });
  }*/

  closeContactList = () => {
    if (this.state.showcontactslist === true)
      this.setState({ showcontactslist: false })
  }

  showContactList() {
    if (!this.state.showcontactslist) {
      this.setState({ showcontactslist: true });
    }
  }


  createRoom = (contact) => {

    let self = this;
    const errorFn = (errorMsg) => {
      if (errorMsg !== this.state.errormessage
        || this.state.showcontactslist === true) {
        this.setState({
          errormessage: errorMsg,
          showcontactslist: false
        });
      }
    }
    
    RaApiRooms.startNewRoom('-', [contact], false)
      .then(function (response) {

        if (response.result.success === true) {
          self.setState({errormessage: null, showcontactslist: false, clickedRoom: response.data });
        } else {
          errorFn(response.result.message);
        }
      })
      .catch(function (errorMessage) {
        errorFn(errorMessage);
      });
  }

  getRooms = (rooms) => {
    if (!rooms || rooms.length === 0) {
      return (
        <List mediaList className="search-list searchbar-found" style={{marginTop: 0}}>
          <ListItem title={localeStrings.no_rooms} />
        </List>
      );
    }

    const ret = rooms.map((item, index) => {

      let lastMessageDate = null;
      if (item.LastMessageDate != null) {
        lastMessageDate = Commons.getDateDescription(item.LastMessageDate, "Europe/Rome", "DD/MM/YYYY");
      }

      let UnreadMessages = null;
      if (item.UnreadMessages && item.UnreadMessages > 0) {
        UnreadMessages = (<span slot="after-start" className="badge" style={{marginRight: '5px'}}>{item.UnreadMessages}</span>);
      }

      return (
        <ListItem key={"room_" + index} 
          link="#" 
          title={item.Subject} 
          after={lastMessageDate} 
          footer={item.LastMessageText}
          onClick={(e) => { this.selectedRoomHandler(item) }}>
            <img slot="media" alt={item.Subject} src={RaUI.getAvatar(item.Subject, '#EFEFF4', '#212121')} width="44" style={{ borderRadius: "50%" }} />
            {UnreadMessages}
          </ListItem>
      );
    });

    return <List mediaList className="search-list searchbar-found" style={{marginTop: 0}}>{ret}</List>;
  }

  selectedRoomHandler = (room) => {
      this.setState({ clickedRoom: room, roomMute: room.Mute });
  }

  componentDidMount = () => {
    Commons.checkAuthentication(this, this, this.$f7router);
    if ((!this.props.contactRooms || this.props.contactRooms.length === 0) && this.props.token !== null) {
      let self = this;
      setTimeout(function () { self.fetchRooms(); }, 1000);
    }
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    Commons.checkAuthentication(this, this, this.$f7router);
    
  }

  fetchRooms = () => {

    const errorFn = (errorMsg) => {
      if (errorMsg !== this.state.errormessage) {
        this.setState({
          errormessage: errorMsg,
        });
      }
    }

    const successFn = (downloadedRooms) => {
      if (this.state.errormessage !== null) {
        this.setState({ errormessage: null });
      }
    }

    RaApiRooms.getContactRooms()
      .then(function (response) {

        if (response.result.success === true) {
          successFn(response.data);
        } else {
          // handle error
          errorFn(response.result.message);
        }
      })
      .catch(function (errorMessage) {
        //handle error
        errorFn(errorMessage);
      });

  };
};

// #region Redux
const mapStateToProps = state => {
  return {
    loggedIn: state.remoteAssistance.loggedIn,
    mainPageIndex: state.app.mainPageIndex,
    incomingCall: state.remoteAssistance.incomingCall,
    contactRooms: state.remoteAssistance.contactRooms,
  };
};

// #endregion

export default connect(mapStateToProps)(Rooms);