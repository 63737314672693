
import appActionTypes from './actionTypes';

const initialState = {
    appInit: false,
    mustUpdateTargets: null,
    orientation: null,
    notificationServiceError: null,
    mainPageIndex: 0,
    newRoomContactsListOpen: false,
    newCallContactsListOpen: false,
    loadingRecents: {loading: false, loadingPerc: 0},
    recents: null,
};

const appReducer = (state = initialState, action) => {
    switch (action.type) {

        case appActionTypes.SET_APP_INIT:
            return {
                ...state,
                appInit: action.appInit,
            }

        case appActionTypes.SET_RECENTS:
            return {
                ...state,
                recents: action.recents,
            }

        case appActionTypes.SET_LOADING_RECENTS:
            return {
                ...state,
                loadingRecents: action.loadingRecents,
            }

        case appActionTypes.SET_NEWCALL_CONTACT_LIST_OPEN:
            return {
                ...state,
                newCallContactsListOpen: action.newCallContactsListOpen,
            }

        case appActionTypes.SET_NEWROOM_CONTACT_LIST_OPEN:
            return {
                ...state,
                newRoomContactsListOpen: action.newRoomContactsListOpen,
            }

        case appActionTypes.SET_ORIENTATION:
            return {
                ...state,
                orientation: action.orientation,
            }
        case appActionTypes.SET_MUST_UPDATE_TARGETS:
            return {
                ...state,
                mustUpdateTargets: action.mustUpdateTargets,
            }
        case appActionTypes.SET_NOTIFICATION_SERVICE_ERROR:
            return {
                ...state,
                notificationServiceError: action.notificationServiceError,
            }
        case appActionTypes.SET_MAINPAGE_INDEX:
            return {
                ...state,
                mainPageIndex: action.mainPageIndex,
            }
    
        default:
        //     RaLog.log("Action sconosciuta: " + action.type);
    }
    return state;
};

export default appReducer;