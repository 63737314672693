import React from 'react';
import { Block, List, ListItem, Popup, Page, Navbar, Link, NavLeft, NavRight, PageContent, Searchbar, BlockTitle } from 'framework7-react';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import {RaApiRooms, RaApiAuth, RaUI, RaApiCalls, RaCordova, RaCordovaPermissions, RaStorage, RaApiRoomsParticipants, RaLog, CONTACT_TYPE} from 'pikkart-remote-assistance';
import Commons from '../Commons';
import localeStrings from './main-rooms-local';
import appLocaleStrings from '../app-local';
import ChatMessages from '../components/commons/chat-messages';
import RoomsPopover from './chat/rooms-popover';
import ContactsList from '../components/commons/contacts-list';
import MenuToolbar from '../components/menu/toolbar';
import RedirectEvents from '../components/commons/redirect-events';
import RoomsLoader from './chat/rooms-loader';
import Home from './home';
import NotificationsAlert from '../components/commons/notifications-alert'
import AppPageComponent from '../components/commons/app-page-component';
import LogoutLoader from '../components/logout/logout-loader';
import {isIOS} from 'react-device-detect';

class MainRooms extends AppPageComponent {


  constructor(props) {
    super(props);

    this.state = {
      errormessage: null,
      updateIntervalId: null,
      clickedRoom: null,
      roomMute: false,
    };

    this.isChatPageAfterIn = false;
  }


  render() {
  //  RaLog.log('render props', this.props);
    let logoutLoader = null;

    let rooms = null;
    let roomsTitle = null;

    let navRight = null;

    if (!RaApiAuth.isAuthenticated()) {
      logoutLoader =  (<LogoutLoader router={this.$f7router}></LogoutLoader>);
    }
    else if (process.env.REACT_APP_CHAT_HOME_ENABLED === "true") {

      if (this.props.contactRooms) {
        rooms = this.getRooms(this.props.contactRooms);
        roomsTitle =  (<BlockTitle>{this.props.contactRooms.length > 0 ? localeStrings.rooms_block_title:null}</BlockTitle>);
      }

      if (this.props.contactRooms !== null && this.contactsForRoomsExist()) {
        navRight = (
            <NavRight>
              <Link searchbarEnable=".searchbar-demo" iconIos="f7:search" iconAurora="f7:search" iconMd="material:search"></Link>
              <Link iconIos="f7:plus" iconAurora="f7:plus" iconMd="material:person_add"
                onClick={(contact) => { this.showContactList(); }}></Link>
            </NavRight>);
      }
    }

    const portrait = Commons.isPortrait(this);

    let navLeft = null;
    if (portrait) {
      navLeft = (<NavLeft>
          <Link panelOpen="left" iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu"></Link>
        </NavLeft>);
    }

    let videoCallLink = null;
    if (RaApiAuth.allowedToCall() && this.state.clickedRoom && this.state.clickedRoom.IsGroup === false) {
      videoCallLink = (<Link slot="right" iconIos="f7:videocam_fill" iconAurora="f7:videocam_fill" iconMd="material:videocam" onClick={(e) => { this.startRoomcall(this.state.clickedRoom);}}
      style={{ opacity: (!RaApiAuth.serviceAvailable() ? 0.3 : 1) }}></Link>);
    }

    return (
      <Page pageContent={false} colorTheme="custom">
        <Navbar noShadow={true} noHairline={true} large largeTransparent sliding={false}>
          {navLeft}
          {navRight}
          <Searchbar
            className="searchbar-demo"
            expandable
            searchContainer=".search-list-rooms"
            searchIn=".item-title"
            disableButton={!this.$theme.aurora}
            placeholder={localeStrings.search_placeholder}
          ></Searchbar>
        </Navbar>
        <PageContent className='main-with-rooms' style={{paddingTop: (!portrait?'110px':'200px'), opacity: this.props.fade === true ? 0 : 1}}>
          
          <Home
            currentCallsHandler={this.currentCallsHandler}
            newInstantCallHandler={this.newInstantCallHandler}
            landingImageLoaded={this.landingImageLoadedHandler} />
          
          <div id="rooms" className="rooms-list" style={{opacity: 0 }}>
            {roomsTitle}
            {rooms}
          </div>

          {logoutLoader}

          <Block className="row demo-preloaders align-items-stretch text-align-center">
              <RoomsLoader></RoomsLoader>
          </Block>
          

          <Block style={{display: this.state.errormessage !== null? 'block':'none' }} className="row demo-preloaders align-items-stretch text-align-center">
              <h3>{localeStrings.list_error}</h3>
          </Block>
            
          <Popup className="contacts-popup ra-popup" id="newroom-contacts-list-popup" colorTheme="custom" onPopupClose={(e) => { this.closeContactList(); }}>
            <ContactsList id="main-contacts-list" key="main-contacts-list" 
              backPageClicked={(contact) => { this.createRoom(contact); }}
              noGroups={true}
              closeContacts={(e) => { 
                this.closeContactList() 
                }}
              isRoomContactList={true} />
          </Popup>

          <Popup className="chat-popup ra-popup" id="main-chat-popup" opened={this.state.clickedRoom !== null} 
            onPopupClosed={() => {this.chatPageClose();}}
            onPopupOpened={() => {this.chatPageAfterIn(); }}>
            <Page style={{height: 'calc(100% + var(--f7-toolbar-height))'}}>
              <Navbar title={this.state.clickedRoom ? this.state.clickedRoom.Subject:null}>
                <Link slot="left" iconIos="f7:xmark" iconAurora="f7:xmark" iconMd="material:close" onClick={(e) => { this.setState({clickedRoom: null}); }}></Link>


                {videoCallLink}

                <Link slot="right" popoverOpen='.popover-menu-room' 
                  iconIos="f7:gear_alt_fill" iconAurora="f7:gear_alt_fill" iconMd="material:more_vert"></Link>

                <RoomsPopover room={this.state.clickedRoom } deleteRoomClick={() => {
                    this.deleteRoomClick(this.state.clickedRoom);
                  }}></RoomsPopover>


              </Navbar>     
                <ChatMessages 
                    roomId={this.state.clickedRoom ? this.state.clickedRoom.UniqueId : null}
                    sendMessageEnabled={true} 
                    attachmentElementId='rooms' 
                    scrollEnabled={true}
                    messagesMarkedAsReadHandler={this.messagesMarkedAsReadHandler}
                    isPageAfterIn={() => { return this.isChatPageAfterIn}}
                ></ChatMessages>
            </Page>
          </Popup>
          <NotificationsAlert></NotificationsAlert>
          <RedirectEvents router={this.$f7router}></RedirectEvents>
        </PageContent>
        <MenuToolbar router={this.$f7router}></MenuToolbar>
      </Page>
    );
  }

  landingImageLoadedHandler = () => {

    if (this.props.fade === true) {
      this.$$('.main-with-rooms').css({
        opacity: 1,
        transition: 'all 2s'
      });
    }
  }

  contactsForRoomsExist = () => {
    if (this.props.userAddressBook && this.props.userAddressBook.length > 0) {
      for (let i=0; i < this.props.userAddressBook.length; i++) {
        if (this.props.userAddressBook[i].Type === CONTACT_TYPE.USER) {
          return true;
        }
      }
    }
    return false;
  }

  startRoomcall = (room) => {

    let self = this;
    if (room && room.IsGroup === false) {
      if (!RaApiAuth.serviceAvailable()) {
        self.$f7.dialog.alert(localeStrings.nominutes_alert_text, localeStrings.nominutes_alert_title);
      }
      else {
        RaApiRoomsParticipants.getParticipants(room.UniqueId)
        .then((response) => {
            if (response.result.success === true) {
              response.data.forEach(participant => {
                if (participant.Contact.UniqueId !== RaStorage.getProfile().UniqueId) {
                  self.setState({clickedRoom: null});
                  self.newInstantCallHandler(participant.Contact);
                }
              });
            }
            else {
              Commons.showErrorMessage(self, response.result.message);
            }
        });
      }
    }
  }

  chatPageClose = () => {
    this.isChatPageAfterIn = false;
    if (this.state.clickedRoom !== null) {
      this.setState({clickedRoom: null})
    }
  }

  chatPageAfterIn = () => {
    if (this.state.clickedRoom !== null) {
        this.isChatPageAfterIn = true;
    }
  }

  deleteRoomClick = (room) => {
    let self = this;
    this.$f7.dialog.confirm(localeStrings.delete_room_alert_title, localeStrings.delete_room_alert_text, (() => {
      self.deleteRoom(self, room); 
    }));
  }

  deleteRoom = (self, room) => {

    const errorFn = (errorMsg) => {
      console.error(errorMsg);
      Commons.showErrorMessage(self, localeStrings.list_error);
    }

    RaApiRooms.deleteContactRoom(room.UniqueId)
      .then(function (response) {

        if (response.result.success === true) {

          self.setState({clickedRoom: null, errormessage: null });
        } else {
          errorFn(response.result.message);
        }
      })
      .catch(function (errorMessage) {
        errorFn(errorMessage);
      });
  }

  closeContactList = () => {
    if (this.contactsPopup && this.contactsPopup.$el) {
      this.$f7.popup.close(this.contactsPopup.$el, true)
    }
    this.props.setNewRoomContactListOpen(false);
  }

  showContactList() {
    this.contactsPopup = this.$f7.popup.open(this.$$('#newroom-contacts-list-popup'), true);
    this.props.setNewRoomContactListOpen(true);
  }


  createRoom = (contact) => {

    let self = this;
    const errorFn = (errorMsg) => {
      console.error(errorMsg);
      Commons.showErrorMessage(self, localeStrings.list_error);
      self.closeContactList();
    }
    
    RaApiRooms.startNewRoom('-', [contact], false)
      .then(function (response) {

        if (response.result.success === true) {
          self.setState({errormessage: null, clickedRoom: response.data });
        } else {
          errorFn(response.result.message);
        }
      })
      .catch(function (errorMessage) {
        errorFn(errorMessage);
      });
  }

  getRooms = (rooms) => {
    const ret = rooms.map((item, index) => {

      let lastMessageDate = null;
      if (item.LastMessageDate != null) {
        lastMessageDate = Commons.getDateDescription(item.LastMessageDate, "Europe/Rome", "DD/MM/YYYY", true);
      }

      let UnreadMessages = null;
      if (item.UnreadMessages && item.UnreadMessages > 0) {
        UnreadMessages = (<span slot="after-start" className="badge" style={{marginRight: '5px'}}>{item.UnreadMessages}</span>);
      }

      return (
        <ListItem key={"room_" + index} 
          link="#" 
          title={item.Subject} 
          after={lastMessageDate} 
          footer={item.LastMessageText}
          onClick={(e) => { this.selectedRoomHandler(item) }}>
            <img slot="media" alt={item.Subject} src={RaUI.getAvatar(item.Subject, '#EFEFF4', '#212121')} width="44" style={{ borderRadius: "50%" }} />
            {UnreadMessages}
          </ListItem>
      );
    });

    return <List mediaList className="search-list-rooms searchbar-found" style={{marginTop: 0}}>{ret}</List>;
  }

  selectedRoomHandler = (room) => {
      this.setState({ clickedRoom: room, roomMute: room.Mute });
  }

  openRoomFromMessage = (message) => {
    RaLog.log('openRoomFromMessage');
    if (message && (!this.state.clickedRoom || this.state.clickedRoom.UniqueId !== message.RoomUniqueId)) {
      let roomToOpen = null;
      if (this.props.contactRooms !== null) {
        for (let i=0; i < this.props.contactRooms.length; i++) {
          if (this.props.contactRooms[i].UniqueId === message.RoomUniqueId) {
            roomToOpen = this.props.contactRooms[i];
            this.setState({ clickedRoom: roomToOpen });
            
            break;
          }
        }
      } 
      else {
        let self = this;
        RaApiRooms.getContactRoom(message.RoomUniqueId)
        .then(response => {
          if (response.result.success === true) {
              self.setState({ clickedRoom: response.data });
          }
        });
      }
      
    }
  }

  componentDidMount = () => {

    let self = this;

    if (this.$f7router.currentRoute.url === '/main/') {
      Commons.checkAuthentication(this, this.$f7router);
    }

    /****************************
     * VISUALIZZAZIONE STANZE
     ***************************/
    if (process.env.REACT_APP_CHAT_HOME_ENABLED === "true") {
      if (this.props.contactRooms && this.props.contactRooms.length > 0) {
        this.$$('.main-with-rooms').css({
          paddingTop: '80px'
        });
        this.$$('.rooms-list').css({
          opacity: 1
        });
      }  
    }

    /****************************
     * APERTURA DA MESSAGGIO
     ***************************/
    if (this.props.notificationMessage) {
      setTimeout(() => {
        self.openRoomFromMessage(self.props.notificationMessage);
      }, 500);
    }

    /****************************
    * GESTIONE EVENTI CHIAMATA
    ****************************/

    RaApiCalls.onActiveCallClosed = () => {
      RaLog.log('active call closed');
      this.setState({ newInstantCallContact: null });

    };

    RaApiCalls.onCallDeclinedByTarget = () => {
      RaLog.log('onCallDeclinedByTarget');
    };

    /****************************
    * GESTIONE DEI PERMESSI
    ****************************/
   if (RaCordova.isCordova() && !isIOS) {
    setTimeout(() => {
       self.checkOverlaypermission()
       .then(() => {
         self.checkCustomManufacturerPermissions();
       });
 
    }, 300);
   }

  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (this.$f7router.currentRoute.url === '/main/') {
      Commons.checkAuthentication(this, this.$f7router);
    }


    /**************************************
     * ANIMAZIONE VISUALIZZAZIONE STANZE
     *************************************/
    if (process.env.REACT_APP_CHAT_HOME_ENABLED === "true") {
      if (this.props.contactRooms && this.props.contactRooms.length > 0 && (!prevProps || !prevProps.contactRooms || prevProps.contactRooms.length === 0)) {
        this.$$('.main-with-rooms').css({
          paddingTop: '80px',
          transition: 'all 2s'
        });
  
        this.$$('.rooms-list').css({
          opacity: 1,
          transition: 'all 2s'
        });
      }
      else if (prevProps && prevProps.contactRooms && prevProps.contactRooms.length > 0 && (!this.props.contactRooms || this.props.contactRooms.length === 0)) {
        this.$$('.main-with-rooms').css({
          paddingTop: '200px',
          transition: 'all 2s'
        });
  
        this.$$('.rooms-list').css({
          opacity: 0,
          transition: 'all 2s'
        });
      }  
    }

    /****************************
     * APERTURA DA MESSAGGIO
     ***************************/
    if (this.props.notificationMessage
        && (!prevProps || !prevProps.notificationMessage || prevProps.notificationMessage.UniqueId !== this.props.notificationMessage.UniqueId)) {
      this.openRoomFromMessage(this.props.notificationMessage);
    }
    
  }

  fetchNewInstantCall = (contact) => {

    this.setState({ spinner: true, newInstantCallContact: contact });

    const errorFn = (errorMsg, errorCode) => {
      let callContact = this.state.newInstantCallContact;

      this.setState({
        calls: null,
        spinner: false,
        /*errormessage: errorCode === 409 || errorCode === 404 || errorMsg === "TargetOccupied" ? null : errorMsg,*/
        newInstantCallContact: null
      });

      Commons.callContactErrorHandler(this, callContact, errorMsg, errorCode, appLocaleStrings);
    }

    const successFn = (call) => {
      let self = this;
      
      setTimeout(function () {
        self.$f7router.navigate('/main-calls/', { props: { showcalling: true, newInstantCallContact: self.state.newInstantCallContact } });
      }, 750);

    }

    RaApiCalls.startNewCall(this.state.newInstantCallContact.Name, [this.state.newInstantCallContact], 'xxxxxxxxxx')
      .then(function (response) {

        if (response.result.success === true) {

          successFn(response.data);
        }
        else {

          errorFn(response.result.message, response.result.code);
        }
      })
      .catch(function (response) {

        errorFn(response.message);
      });

  }

  newInstantCallHandler = (contact) => {
    if (RaCordova.isCordova()) {
      const self = this;
      RaCordovaPermissions.askCallPermissions()
        .then(function (response) {
          RaLog.log('request permissions success ' + JSON.stringify(response));
          self.fetchNewInstantCall(contact);
        })
        .catch(function (response) {
          RaLog.log('request permissions failed ' + JSON.stringify(response));

          self.$f7.dialog.alert(localeStrings.permissions_alert_message, localeStrings.permissions_alert_title, function () {

          });
        });
    }
    else {
      this.fetchNewInstantCall(contact);
    }
  }


  checkCustomManufacturerPermissions = () => {

    const self = this;

    return new Promise((resolve, reject) => {

      const settingsRequired = (deviceInfo) => {
        self.$f7.dialog.alert(localeStrings.other_settings_alert_text, localeStrings.other_settings_alert_title, () => {
          RaCordovaPermissions.askOtherSettingsScreenPermission([deviceInfo.manufacturer])
            .then(function () {
              RaLog.log('other settings permission opened');
            })
            .catch(function () {
              RaLog.log('error opening other settings screen');
            })
            .finally(() => {
              resolve();
            })
        });
      }

      let gotPermissioninfo = localStorage.getItem('gotPermissioninfo');
      if (!gotPermissioninfo) {
        RaCordova.getDeviceInfo().then((deviceInfo) => {
          RaCordovaPermissions.areBackgroundServiceAndLockScreenNotificationPermissionsRequired(deviceInfo.manufacturer).then(() => {
            localStorage.setItem('gotPermissioninfo', 'true');
            settingsRequired(deviceInfo);
          })
          .catch(() => {
            RaLog.log('other settings not required for this device');
            localStorage.setItem('gotPermissioninfo', 'true');
            resolve();
          });
        });
      }
      else {
        resolve();
      }


    });
  }
  
  checkOverlaypermission = () => {
    const self = this;
    return new Promise((resolve, reject) => {
      if (RaCordova.isCordova()) {
        RaCordovaPermissions.checkOverlayPermission()
          .then(function () {
            RaLog.log('overlay permission granted');
            resolve();
          })
          .catch(function (response) {
            self.$f7.dialog.alert(localeStrings.permission_alert_text, localeStrings.permission_alert_title, () => {
              
              RaCordovaPermissions.askOverlayPermission()
                .then(function () {
                  RaLog.log('overlay permission granted');
                })
                .catch(function () {
                  self.$f7.dialog.alert(localeStrings.permission_alert_text2, localeStrings.permission_alert_title)
                })
                .finally(() => {
                  resolve();
                });
            });
          });
      }
    });
  }


};

// #region Redux
const mapStateToProps = state => {
  return {
    userAddressBook: state.remoteAssistance.userAddressBook,
    contactRooms: state.remoteAssistance.contactRooms,
    loggedIn: state.remoteAssistance.loggedIn,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setNewRoomContactListOpen: (newRoomContactsListOpen) => dispatch(actions.setNewRoomContactListOpen(newRoomContactsListOpen)),
  }
};

// #endregion
export default connect(mapStateToProps, mapDispatchToProps)(MainRooms);