
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    slide1_title: "Power on Live Glasses",
    slide1_subtitle: "Please follow this procedure only if the Live Glasses has been reset.",
    slide1_description1: "Press the touching area for 2 seconds, it takes about 30 seconds.",
    slide1_description2: "The green light will be on, the blue light will flicker.",
    button_next: "NEXT",
    button_prev: "PREV",
    button_settings: "SETTINGS...",
    button_connect: "CONNECT",
    button_retry: "RETRY",
    slide2_title: "Connect to Live Glasses WIFI",
    slide2_description1: "Turn on your WIFI network.",
    slide2_description2: "Connect to Live Glasses WIFI (named by UID code).",
    slide3_title: "Configure Live Glasses",
    slide3_description1: "Check your Router WIFI name and password to configure Live Glasses",
    slide4_title: "Check your connection",
    slide4_description1: "Now you should not be connected anymore to Live Glasses WIFI and Live Glasses should be tuned off after configuration.",
    slide4_description2: "Activate your Router WIFI.",
    slide4_description3: "Turn on your Live Glasses, wait about 30 seconds, the green and the blue light will be on, that means the Live Glasses are succesfully connected",
    slide4_sheet_linktext: "Something wrong?",
    slide4_sheet_title: "Something wrong?",
    slide4_sheet_description: "If the blue light goes on flickering, it means that Live Glasses are not connected. So follow these steps:",
    slide4_sheet_option1: "Please check that your Router WIFI is turned on. If not, activate it now and wait for Live Glasses to connect to it (about 30 seconds).",
    slide4_sheet_option2: "Please check that Live Glasses are turned on, because they turned off after settings, then wait for Live Glasses to connect to your Router WIFI (about 30 seconds).",
    slide4_sheet_option3: "If the blue light goes on flickering, maybe you set a wrong Router WIFI name or password. So you need to reset your Live Glasses and repeat the whole procedure.",
    slide5_title: "Connection to Live Glasses",
    slide5_connecting: "Connection in progress...",
    slide5_connected1: "Congratulation, your Live Glasses have been successfully connected to your device.",
    slide5_connected2: "Enjoy your remote assistance experience.",
    connectToIpCamWifi_alert1_title: "Not connected",
    connectToIpCamWifi_alert1_text: "Please connect to the Live Glasses WIFI before to procede to the next step",
    getIpCamIpAddress_alert: 'Live Glasses not found...wait some seconds and try again',
    isRouterWifiActive_error1: 'Please enable your Router WIFI',
    isRouterWifiActive_error2: 'Unable to detect your Router WIFI',
  },
  it: {
    slide1_title: "Accendi i Live Glasses",
    slide1_subtitle: "Esegui questa procedura solo se i Live Glasses sono stati resettati.",
    slide1_description1: "Premi l'area di tocco per 2 secondi, attendi circa 30 secondi.",
    slide1_description2: "Si deve accendere la luce verde, la luce blu invece lampeggerà.",
    button_next: "AVANTI",
    button_prev: "INDIETRO",
    button_settings: "IMPOSTAZIONI...",
    button_connect: "CONNETTI",
    button_retry: "RIPROVA",
    slide2_title: "Connettiti alla WIFI dei Live Glasses",
    slide2_description1: "Attiva la rete WIFI.",
    slide2_description2: "Connettiti alla WIFI dei Live Glasses (il nome corrisponde al codice UID).",
    slide3_title: "Configura i Live Glasses",
    slide3_description1: "Verifica il nome e la password del tuo Router WIFI per configurare i Live Glasses",
    slide4_title: "Verifica la tua connessione",
    slide4_description1: "Ora non dovresti più essere connesso alla WIFI dei Live Glasses e i Live Glasses si dovrebbero essere spenti a seguito della configurazione.",
    slide4_description2: "Attiva il tuo Router WIFI.",
    slide4_description3: "Accendi i Live Glasses, attendi circa 30 secondi, se la luce verde e la luce blu rimangono accese significa che i Live Glasses sono connessi correttamente",
    slide4_sheet_linktext: "Qualcosa non va?",
    slide4_sheet_title: "Qualcosa non va?",
    slide4_sheet_description: "Se la luce blu continua a lampeggiare, significa che i Live Glasses non sono connessi. Quindi segui i seguenti passi:",
    slide4_sheet_option1: "Verifica che il tuo Router WIFI sia attivo. Se non lo è, attivalo adesso e attendi che i Live Glasses si connettano ad esso (circa 30 secondi).",
    slide4_sheet_option2: "verifica che i Live Glasses siano accesi, perché si spengono automaticamente dopo la configurazione, dopodiché attendi si connettano al tuo Router WIFI (circa 30 secondi).",
    slide4_sheet_option3: "Se la luce blu continua a lampeggiare, può darsi che in fase di configurazione tu abbia indicato in modo errato lo username o la password del tuo Router WIFI. In questo caso devi resettare i Live Glasses e ripetere l'intera procedura.",
    slide5_title: "Connessione ai Live Glasses",
    slide5_connecting: "Connessione in corso...",
    slide5_connected1: "Congratulazioni, i tuoi Live Glasses si sono connessi crrettamente al tuo dispositivo.",
    slide5_connected2: "Goditi la tua assistenza remota.",
    connectToIpCamWifi_alert1_title: "Non connesso",
    connectToIpCamWifi_alert1_text: "Connettiti alla WIFI dei Live Glasses prima di procedere al prossimo step",
    getIpCamIpAddress_alert: 'Live Glasses non rilevati...attendi qualche secondo e riprova',
    isRouterWifiActive_error1: 'Attiva il tuo Router WIFI',
    isRouterWifiActive_error2: 'Impossibile rilevare il tuo Router WIFI',
  }
});
  
  export default localeStrings;