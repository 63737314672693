import { connect } from 'react-redux';
import { RaUIAnnotationCanvas, RaLog } from 'pikkart-remote-assistance';
import React from 'react';
import { Block, Col, Preloader } from 'framework7-react';
import localeStrings from './annotation-canvas-local';

export class AnnotationCanvas extends RaUIAnnotationCanvas {

  render() {

    let annotationCanvas = null;

    if (this.props.selectedSnapshot) {
      if (this.state.spinner === true) {
        return (
          <Block className="row demo-preloaders align-items-stretch text-align-center">
            <Col>
              <Preloader size={42}></Preloader>
            </Col>
          </Block>
        );
      }
      else {
        annotationCanvas = (<RaUIAnnotationCanvas
          live={this.props.live}
          cancelAnnotationButton={this.props.cancelAnnotationButton}
          backgroundImage={this.props.selectedSnapshot}
          backgroundImageSrc={this.props.selectedSnapshotImgSrc}
          imageUniqueId={this.props.selectedSnapshot.UniqueId}
          callId={this.props.callId}
          dataTrack={this.props.dataTrack}
          myProfile={this.props.myProfile}
          marginBottom={this.props.marginBottom}
          $$={this.props.$$}
          alt={this.props.alt} />);
        return (annotationCanvas);
      }
    }
    else {
      return (
      <Block className="row demo-preloaders align-items-stretch text-align-center">
        <Col>
          <h3 style={{color: 'white'}}>{localeStrings.select_snapshot}</h3>
        </Col>
      </Block>);
    }

  }

  
  componentDidUpdate = (prevProps, prevState, snapshot) => {
    RaLog.log('AnnotationCanvas updated');
  }
}

const mapStateToProps = state => {
  return {
    takenSnapshots: state.remoteAssistance.takenSnapshots,
    selectedSnapshot: state.remoteAssistance.selectedSnapshot,
    selectedSnapshotImgSrc: state.remoteAssistance.selectedSnapshotImgSrc,
    dataTrack: state.remoteAssistance.dataTrack,
    myProfile: state.remoteAssistance.profile,
    isArVideoInputDeviceEnable: state.remoteAssistance.isArVideoInputDeviceEnable,
    selectedArVideoInputDevice: state.remoteAssistance.selectedArVideoInputDevice,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    
  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(AnnotationCanvas);