import React from 'react';
import { connect } from 'react-redux';
import {RaMedia} from 'pikkart-remote-assistance';
import localeStrings from './inputarvideo-options-local';

class InputArVideoOptions extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      
    };
  }


  render() {

    let arVideoInputDevices = null;
    if (this.props.arVideoInputDevices && this.props.arVideoInputDevices.length > 0) {

      let selectedDevice = RaMedia.getSelectedArVideoInputDevice();
      if (!selectedDevice) {
        RaMedia.selectDefaultDevices();
      }

      arVideoInputDevices = this.props.arVideoInputDevices.map((item, index) => {
  
        return (
            <option key={"video_" + index} 
              value={item.deviceId}>
                {item.label ? item.label : ('Camera'+(this.props.arVideoInputDevices.length > 1? ' #'+index:''))}
            </option>
        );

      });
    }
    else {
      arVideoInputDevices = <option key="video_null" value="" >{localeStrings.inputVideoNoOptions}</option>;
    } 

    return (arVideoInputDevices);
  }

  componentDidMount = () => {
    
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    
    if (this.props.arVideoInputDevices
      && (!prevProps || !prevProps.arVideoInputDevices || JSON.stringify(this.props.arVideoInputDevices) !== JSON.stringify(prevProps.arVideoInputDevices))) {
        let selectedDevice = RaMedia.getSelectedArVideoInputDevice();
        if (this.props.containerSelector && selectedDevice) {
          this.$$(this.props.containerSelector).val(selectedDevice.deviceId);
        }
      }
  }
};

// #region Redux
const mapStateToProps = state => {
  return {
    arVideoInputDevices: state.remoteAssistance.arVideoInputDevices,
    selectedArVideoInputDevice: state.remoteAssistance.selectedArVideoInputDevice,
  };
};

const mapDispatchToProps = dispatch => {
  return {

  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(InputArVideoOptions);