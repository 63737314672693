
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    title: 'Filters',
    dateFrom: 'Sent from date',
    dateTo: 'Sent to date',
    datePlaceHolder: 'Please choose...',
    sendReceived: 'Sent/Received',
    sendReceived_sent: 'Sent',
    sendReceived_received: 'Received',
    sendReceived_all: 'All',
    contact: 'Contact',
    contactSearchPlaceHolder: 'Search',
    contactSearchEverybody: 'No filter',
    contactSearchClose: 'Close',
    fileName: 'File name',
    applyButton: 'Apply filters',
  },
  it: {
    title: 'Filtri',
    dateFrom: 'Inviato dopo il',
    dateTo: 'Inviato prima del',
    datePlaceHolder: 'Scegli...',
    sendReceived: 'Inviati/Recevuti',
    sendReceived_sent: 'Inviati',
    sendReceived_received: 'Recevuti',
    sendReceived_all: 'Tutti',
    contact: 'Contatto',
    contactSearchPlaceHolder: 'Cerca',
    contactSearchEverybody: 'Tutti',
    contactSearchClose: 'Chiudi',
    fileName: 'Nome del file',
    applyButton: 'Applica filtri',
  }
});
  
  export default localeStrings;