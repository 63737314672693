import React from 'react';
import { connect } from 'react-redux';
import {RaMedia, RaApiCalls} from 'pikkart-remote-assistance';

class InputVideoPreview extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      
    };

    this.videoTrack = null;
  }


  render() {


    return (<div slot="content" className="settings-videotrack"></div>);
  }

  componentDidMount = () => {
    if (!this.props.selectedVideoInputDevice 
          && this.props.videoInputDevices && this.props.videoInputDevices.length > 0) {
      RaMedia.selectVideoInputDevice(this.props.videoInputDevices[0]);
    }

    if (this.props.selectedVideoInputDevice) {
      this.showVideoTrack();
    }
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    
    if (!this.props.selectedVideoInputDevice 
      && this.props.videoInputDevices && this.props.videoInputDevices.length > 0) {
      RaMedia.selectVideoInputDevice(this.props.videoInputDevices[0]);
    }

    if (this.props.selectedVideoInputDevice) {
      let selectedVideoInputDeviceChanged =  (!prevProps || !prevProps.selectedVideoInputDevice || prevProps.selectedVideoInputDevice.deviceId !== this.props.selectedVideoInputDevice.deviceId);
      let activeCallChanged = (this.props.activeCall && (!prevProps || !prevProps.activeCall)) || (!this.props.activeCall && prevProps && prevProps.activeCall ); 
      let activeCallClosed = (!this.props.activeCall && prevProps && prevProps.activeCall ); 
      let selectedSnapshotChanged = (this.props.selectedSnapshot && (!prevProps || !prevProps.selectedSnapshot)) || (!this.props.selectedSnapshot && prevProps && prevProps.selectedSnapshot ); 

      if (selectedVideoInputDeviceChanged || selectedSnapshotChanged || (activeCallChanged && !activeCallClosed)) {
        this.showVideoTrack();
      }
    }
    else {
      this.$$('.settings-videotrack').html('');
    }
    
  }

  componentWillUnmount = () => {
    
    //se non c'è una chiamata in corso e ho una traccia video aperta la chiudo
    if (!RaApiCalls.getActiveCall()) {
      RaMedia.stopVideoInputDeviceTrack();
    }
  }


  showVideoTrack = () => {

    let self = this;
    RaMedia.getVideoInputDeviceTrack()
    .then(track => {
      self.$$('.settings-videotrack').html('');
      if (track) {
        self.$$('.settings-videotrack').append(track.attach());
      }
    })
    .catch(errorMessage => {
        console.error(errorMessage);
    }); 
  }
};

// #region Redux
const mapStateToProps = state => {
  return {
    selectedVideoInputDevice: state.remoteAssistance.selectedVideoInputDevice,
    videoInputDevices: state.remoteAssistance.videoInputDevices,
    activeCall: state.remoteAssistance.activeCall,
    selectedSnapshot: state.remoteAssistance.selectedSnapshot
  };
};

const mapDispatchToProps = dispatch => {
  return {

  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(InputVideoPreview);