
import { RaApiAuth, RaStorage, RaCordova, RaUI, RaLog, CONTACT_ROLE } from 'pikkart-remote-assistance';
import moment from 'moment-timezone';
import appLocaleStrings from './app-local';
import { isIOS } from 'react-device-detect';

export class Commons {

  static MAINPAGEINDEX_HOME = 0;
  static MAINPAGEINDEX_RECENTS = 1;
  static MAINPAGEINDEX_DOCUMENTS = 2;
  static MAINPAGEINDEX_IPCAMSETTINGS = 3;
  static MAINPAGEINDEX_DOCUMENTS = 4;
  static MAINPAGEINDEX_PROFILE = 5;
  static MAINPAGEINDEX_CHAT = 6;
  static MAINPAGEINDEX_SETTINGS = 7;
  static MAINPAGEINDEX_CREDITS = 8;


  static scrollTo = (element, to, duration) => {
    var start = element.scrollTop,
        change = to - start,
        currentTime = 0,
        increment = 20;
        
    var animateScroll = function() {        
        currentTime += increment;
        var val = Commons.easeInOutQuad(currentTime, start, change, duration);
        element.scrollTop = val;
        if(currentTime < duration) {
            setTimeout(animateScroll, increment);
        }
    };

    animateScroll();
  }

  //t = current time
  //b = start value
  //c = change in value
  //d = duration
  static easeInOutQuad = (t, b, c, d) => {
    t /= d/2;
    if (t < 1) return c/2*t*t + b;
    t--;
    return -c/2 * (t*(t-2) - 1) + b;
  }

  static checkOpenerMessage = (router) => {
    
    if (RaCordova.isCordova()) {
      RaCordova.getAppOpenerMessage().then(message => {
        RaLog.log('getAppOpenerMessage', message);
        if (message && message.RoomUniqueId && router.currentRoute.url !== "/rooms/") {
//          router.navigate('/rooms/', {reloadAll: true, props : {roomId: message.RoomUniqueId }});
        }
      }).catch((error) => {console.error(error) });
    }
  };

  static getUnreadMessages = (rooms) => {
    let count = 0;
    if (rooms) {
      rooms.forEach(room => {
        count += room.UnreadMessages;
      });
    }
    return count;
  }

  static isPortrait = (self) => {
    
    return !isIOS && (self.$$(window).width() < self.$$(window).height() || Commons.homeWithCamera());
  }
  
  static groupItemsByDate = (items, datePropName, tz, format) => {
    let result = []
    let date = new Date();
    let today = moment(date).tz("Europe/Rome").format("DD/MM/YYYY");
    let yesterday = moment(date.setDate(date.getDate() - 1)).tz("Europe/Rome").format("DD/MM/YYYY");

    let lastDateName = null;
    for (var i = 0; i < items.length; i++) {
      let item = items[i];
      let dateToCheck = moment(item[datePropName]).tz("Europe/Rome").format("DD/MM/YYYY");
      let dateName = dateToCheck;
      if (dateName === today)
        dateName = appLocaleStrings.today;
      else if (dateName === yesterday)
        dateName = appLocaleStrings.yesterday;
      if (lastDateName !== dateName)
        result.push({ dateName: moment(item[datePropName]).tz(tz).format(format), items: [item] });
      else
        result[result.length - 1].items.push(item);
      lastDateName = dateName;
    }

    return result;
  }

  static getContact = (contactId, contacts) => {
    let contact = null;

    if (contacts) {
      for (let i=0; i < contacts.length; i++) {
        if (contacts[i].UniqueId === contactId) {
          contact = contacts[i];
          break;
        }
      }
    }

    return contact;
  }

  static isValidUrl = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }

  static getDateDescription = (date, tz, format, timeIfToday) => {
    
    let currDate = new Date();

    let today = moment.utc(currDate).tz(tz).format("DD/MM/YYYY");
    let yesterday = moment.utc(currDate.setDate(currDate.getDate() - 1)).tz(tz).format("DD/MM/YYYY");
    let dateDescription = moment.utc(date).tz(tz).format("DD/MM/YYYY");

    if (dateDescription === today) {
      if (timeIfToday === true) {
        dateDescription = moment.utc(date).tz(tz).format("HH:mm");
      }
      else {
        dateDescription = appLocaleStrings.today;
      }
    }
    else if (dateDescription === yesterday) {
      dateDescription = appLocaleStrings.yesterday;
    }
    else if (currDate.getUTCFullYear() === moment.utc(date).year()) {
      dateDescription = moment.utc(date).tz(tz).format("D MMM");
    }
    else {
      dateDescription = moment.utc(date).tz(tz).format(format);
    }
    return dateDescription;
  }

  static showErrorMessage = (self, text) => {
    //if (!this.toastBottom) {
    let toastBottom = self.$f7.toast.create({
      text: text,
      closeTimeout: 2000,
    });
    //}
    toastBottom.open();
  };

  static showAlert = (self, text, title) => {
    self.$f7.dialog.alert(text, title);
  }

  static _lastCheckResultAuthenticated = null;
  static checkAuthentication = (self, router) => {
    
    if (!router) {
      console.error('router param is null')
      return;
    }
    else if (!router.currentRoute) {
      console.error('router.currentRoute param is null')
      return;
    }

    let authenticated = RaApiAuth.isAuthenticated();
    
    if (!authenticated && router.currentRoute.url !== "/login/") {
      if (Commons._lastCheckResultAuthenticated === null || Commons._lastCheckResultAuthenticated === true) {
        RaLog.log('checkAuthentication failed...going to login');
            
        //chiudo tutti i popup
        let popups =  self.$$('.ra-popup');
        popups.forEach(popup => {
          self.$f7.popup.close('#'+popup.id, true);
        })
        
        //rimando al login
        router.navigate('/login/', {reloadAll: true});
      }
    }
    Commons._lastCheckResultAuthenticated = authenticated;
  };

  static hasIpCam = () => {
    //return true;
    let profile = RaStorage.getProfile();
    return (RaCordova.isCordova() && profile && profile.IpCamsEnabled && profile.IpCamsEnabled.length > 0);
  }

  static homeWithCamera = () => {
    let profile = RaStorage.getProfile();
    return profile && profile.Role && profile.Role.UxStartsWithCamera;
  }

  static shareUserVideoTrack = () => {
  /*  let profile = RaStorage.getProfile();
    return profile && profile.Role && profile.Role.Code === CONTACT_ROLE.ASSISTED;*/
    return true;
  }
  
  static getVideoCropSizeCallback = (self) => {
    let profile = RaStorage.getProfile();
    if (profile && profile.Role && profile.Role.Code === CONTACT_ROLE.OPERATOR) {
      let minSize = self.$$(window).width()/4;
      if (minSize > self.$$(window).height()/6) {
        minSize = self.$$(window).height()/6;
      }
      return {width: minSize, height: minSize};
    }
    else {
      return {width: self.$$(window).width(), height: self.$$(window).height()};
    }
  }

  static getVideoCropData = (isAssistedContact, isLocalView, cropSize, trackWidth, trackHeight) => {

    let videoCropData = null;
    if (isAssistedContact) {
      if (isLocalView) {
        videoCropData = RaUI.getElementCropData(cropSize.width, cropSize.height, trackWidth, trackHeight);
        if (cropSize.width > 1025) {
          videoCropData.margin.left = 1025*0.15*-1;
        }
      }
      else {
        videoCropData = RaUI.getElementFitData(cropSize.width, cropSize.height, trackWidth, trackHeight);
      }
    }
    else {
      videoCropData = RaUI.getElementCropData(cropSize.width, cropSize.height, trackWidth, trackHeight);
    }
    return videoCropData;
  }

  static setVideoTrackNodeStyle = (self, nodeSelector, videoCropData, isAssistedContact, isLocalView) => {
    self.$$(nodeSelector).attr("style", "width:" + videoCropData.width + "px;height:" + videoCropData.height + "px;margin-top:" + videoCropData.margin.top+"px;margin-left:"+videoCropData.margin.left+"px;overflow: hidden");
  }

  static callContactErrorHandler = (self, targetContact, errorMsg, errorCode, appLocaleStrings) => {

    let message = null;

    if (targetContact && targetContact.Company
        && targetContact.Company.UnavailableMessage) { 
      message = targetContact.Company.UnavailableMessage;
    }

    if (!message) {
      if (errorCode === 404) {
        message = (targetContact? targetContact.Name:'') + appLocaleStrings.callResult_NoTargetAvailable;
      }
      else {
        message = errorMsg;
      }
    }
    
    if (errorCode === 409) {
      message = (targetContact? targetContact.Name:'') + appLocaleStrings.callResult_TargetOccupied;
    } 
    
    if (errorCode === 412) {
      message = appLocaleStrings.callResult_userUnavailable;
    } 
    
    if (errorCode === 417) {
      message = appLocaleStrings.callResult_subscriptionRequired;
    } 

    if (message && message.length > 100) {
      message = message.substring(0, 100) + '...';
    }

    self.$f7.dialog.alert(message, appLocaleStrings.callResult_ErrorTitle);

  }

}


export default Commons;