import React from 'react';
import { Preloader, Block, Col } from 'framework7-react';

const Spinner = (props) => {
    return (
        <Block className="row demo-preloaders align-items-stretch text-align-center spinner" style={{paddingTop: '150px', marginTop: 0}}>
        <Col style={{backgroundColor: 'none'}}>
          <Preloader size={42}></Preloader>
        </Col>
      </Block>
    );
}
export default Spinner;