
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    tab_left_home: "Home",
    tab_left_chat: "Messages",
    tab_left_recents: "Recents",
    tab_left_documents: "Documents",
    tab_left_glasses: "Glasses settings",
    tab_left_settings: "Settings",
    tab_left_privacy: "Privacy & Terms of service",
    tab_left_credits: "Credits",
  },
  it: {
    tab_left_home: "Home",
    tab_left_chat: "Messaggi",
    tab_left_recents: "Recenti",
    tab_left_documents: "Documenti",
    tab_left_glasses: "Live glasses",
    tab_left_settings: "Impostazioni",
    tab_left_privacy: "Privacy & Termini di utilizzo",
    tab_left_credits: "Credits",
  }
});
  
  export default localeStrings;