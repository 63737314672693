
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    no_rooms: "No chat to display",
    list_error: 'Oops...something went wrong',
    messages_title: 'Messages',
    search_placeholder: 'Search',
    delete_room_alert_title: 'Delete',
    delete_room_alert_text: 'Do you want to delete this chat?',
  },
  it: {
    no_rooms: "Non hai alcuna chat attiva",
    list_error: 'Oops...qualcosa è andato storto',
    messages_title: 'Messaggi',
    search_placeholder: 'Cerca',
    delete_room_alert_title: 'Elimina',
    delete_room_alert_text: 'Vuoi eliminare questa chat?',
  }
});
  
  export default localeStrings;